<div class="htoolbar assetDetail">
  <div class="firstrow">
    <div class="headertext">
      <a (click)="redirectPage('dashboard')" class="cursor-pointer">Dashboard</a>
      /
      <b>{{ selectedAssetData?.customName }}</b>
    </div>
    <div class="toolbar">
      <ng-container *ngIf="sidebarSelected.title == 'Status'">
        <div class="padding-right-10p dropdown" *ngIf="assetData.length > 0">
          <ng-container *ngIf="KPIList && KPIList?.length">
            <app-dropdown [values]="KPIList" [selected]="selectedKpi" [displayMember]="'label'"
              (newSelectionEvent)="changeKPI($event)" type="object">
            </app-dropdown>
          </ng-container>
        </div>
      </ng-container>
      <div class="padding-right-10p" style="position: relative">
        <!-- <span class="data_frequency">{{data_frequency}}</span> -->
        <ng-container *ngIf="selectedFilter == 'Custom'">
          <app-daterangepicker [InitialDates]="InitialDates"
            (selectedDates)="customchangeDuration($event)"></app-daterangepicker>
          <!-- <app-mat-daterangepicker
          [InitialDates]="InitialDates"
          (selectedDates)="customchangeDuration($event)">
        </app-mat-daterangepicker> -->
        </ng-container>
      </div>
      <div class="padding-right-10p dropdown" *ngIf="assetData.length > 0">
        <app-dropdown [values]="assetData" [selected]="selectedAssetData" [displayMember]="'customName'"
          (newSelectionEvent)="changeAsset($event)" type="object">
        </app-dropdown>
      </div>
      <ng-container
        *ngIf="sidebarSelected.title != 'Overview' && sidebarSelected.title != 'Status' && sidebarSelected.title!='Trends Monitor' ">
        <div class="padding-right-10p">
          <app-dropdown [values]="filterList" [selected]="selectedFilter" (newSelectionEvent)="duractionChange($event)">
          </app-dropdown>
        </div>
      </ng-container>


      <!-- <div class="padding-right-10p ">
                <button type="button" class="btn btn-info " (click)="duractionChange('Live')">
                    <i class="fa fa-circle"></i> LIVE</button>
            </div> -->
      <div class="padding-right-10p">
        <button type="button" class="btn btn-info" (click)="redirectPage('dataset')">
          <i class="fa fa-table"></i> Live Tags
        </button>
      </div>
      <div class="padding-right-10p">
        <button type="button" class="btn btn-info" (click)="redirectPage('monitor')">
          <i class="fa fa-dashboard"></i> Monitor
        </button>
      </div>
      <div class="padding-right-10p">
        <button *ngIf="checkAction('Add Widget')" type="button" class="btn btn-info"
          (click)="open(content, 'ADDWIDGET')">
          <i class="fa fa-plus-circle"></i> Add Widget
        </button>
      </div>
      <div class="padding-right-10p">
        <button type="button" class="btn btn-info" (click)="reloadComponent()">
          <i class="fa fa-refresh" title="Refresh"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="d-fc p-20">
  <div class="row">
    <div class="col-md-2 padding-right-5">
      <app-sidebarmenu [selectedAssetData]="selectedAssetData" [sidebarSelected]="sidebarSelected"
        (menuClick)="sidebarMenuClicked($event)"></app-sidebarmenu>
    </div>
    <div class="col-md-10">
      <div [ngSwitch]="sidebarSelected.title">
        <div class="row" *ngSwitchDefault>
          <ng-container *ngIf="sidebarSelected?.tabs && sidebarSelected?.tabs.length > 0">
            <div class="padding10 col-12">
              <div class="card tabCard">
                <div class="tab-wrap">
                  <div class="tab-content" *ngFor="let tab of sidebarSelected.tabs; let i = index"
                    [ngStyle]="{ width: tabsWidth + 'px' }" (click)="navigateToTab(tab, i)">
                    <div class="tab-label-content">
                      <label for="{{ tab.tabId }}">{{ tab.title }}</label>
                    </div>
                  </div>
                  <div class="slide" [ngStyle]="{
                      width: tabsWidth + 'px',
                      left: tabsWidth * selectedIndex + 'px'
                    }"></div>
                </div>
                <ng-container *ngFor="let tab of sidebarSelected.tabs; let i = index">
                  <ng-container *ngIf="selectedIndex == i">

                    <div class="row">
                      <div class="padding10 chartDiv" [ngClass]="widget.width" *ngFor="let widget of widgetData">
                        <div class="card" *ngIf="widget.category == 'CHART'">
                          <div class="cardTitle">
                            <div class="row  pos-relative">
                              <div class="col-sm-5" style="margin:0;padding: 5px">{{ widget.title }}</div>
                              <div class="col-sm-7 text-right icon  d-flex justify-content-end align-items-center">
                                <!-- <ng-container *ngIf="selectedFilter == 'Live'">
                                  <i
                                    class="fa fa-play"
                                    *ngIf="!widget.live"
                                    title="Play live data"
                                    (click)="widget.live = !widget.live"
                                  ></i>
                                  <i
                                    class="fa fa-pause"
                                    title="Pause live data"
                                    *ngIf="widget.live"
                                    (click)="widget.live = !widget.live"
                                  ></i>
                                </ng-container> -->
                                <div class="padding-right-10p small-dropdown">
                                  <app-dropdown [values]="filterList" [selected]="selectedFilter"
                                    (newSelectionEvent)="graphDurationChnage($event,widget)">
                                  </app-dropdown>
                                </div>
                                <ng-container *ngIf="widget.filter == 'Custom'">
                                  <i class="fa fa-calendar-o" aria-hidden="true"
                                    style="right: 195px;font-size: 20px;position: absolute;opacity: 1;top: 6px;">
                                    <app-daterangepicker [hide]="true" [InitialDates]="InitialDates"
                                      (selectedDates)="customchangeDuration($event)"></app-daterangepicker>
                                  </i>
                                </ng-container>
                                <i class="fa fa-download" title="Download data"
                                  (click)="download('live', widget.chartname)"></i>
                                <i class="fa fa-arrows-alt" title="show  chart" (click)="
                                    open(chartContent, 'CHARTPOPUP', widget)
                                  "></i>
                              </div>
                            </div>
                          </div>
                          <div>
                            <ng-container *ngIf="widget.loading">
                              <div style="display: grid; place-items: center;height: 280px;">
                                <app-card-spinner></app-card-spinner>
                              </div>

                            </ng-container>

                            <ng-container *ngIf="
                              !widget.loading &&
                                widget.chart_data &&
                                widget.chart_data.length > 0
                              ">
                              <app-linechart [data]="widget.chart_data"></app-linechart>
                            </ng-container>
                          </div>
                        </div>
                        <ng-container *ngIf="widget.category == 'SINGLE_CARD'">
                          <card-single [widgetInfo]="widget"></card-single>
                        </ng-container>
                        <ng-container *ngIf="widget.category == 'HEADING'">
                          <app-heading [widgetInfo]="widget"></app-heading>
                        </ng-container>
                        <ng-container *ngIf="widget.category == 'LIST_CARD'">
                          <app-card-list [widgetInfo]="widget"></app-card-list>
                        </ng-container>
                        <ng-container *ngIf="widget.category == 'GRID_CARD'">
                          <app-card-grid [widgetInfo]="widget"></app-card-grid>
                        </ng-container>
                        <ng-container *ngIf="widget.category == 'SHIFTWISE'">
                          <app-shiftwise [widgetInfo]="widget"></app-shiftwise>
                        </ng-container>
                        <ng-container *ngIf="widget.category == 'DIAGRAM'">
                          <app-diagram [widgetInfo]="widget"></app-diagram>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!sidebarSelected?.tabs || sidebarSelected?.tabs.length == 0">

            <ng-container  *ngFor="let widget of widgetData">
              <ng-container
              *ngIf="!widget.userSpecific || (widget.userSpecific && widget.userSpecific == 'Current User' && widget.email==this.loggedinUser.email)">
              <div class="padding10 chartDiv" [ngClass]="widget.width" >

                <div class="card" *ngIf="widget.category == 'CHART'">
                  <div class="cardTitle">
                    <div class="row  pos-relative">
                      <div class="col-sm-5" style="margin:0;padding: 5px">{{ widget.title }}</div>
                      <div class="col-sm-7 text-right icon d-flex justify-content-end align-items-center">
                        <!-- <ng-container *ngIf="selectedFilter == 'Live'">
                          <i
                            class="fa fa-play"
                            *ngIf="!widget.live"
                            title="Play live data"
                            (click)="widget.live = !widget.live"
                          ></i>
                          <i
                            class="fa fa-pause"
                            title="Pause live data"
                            *ngIf="widget.live"
                            (click)="widget.live = !widget.live"
                          ></i>
                        </ng-container> -->
                        <div class="padding-right-10p small-dropdown">
                          <app-dropdown [values]="filterList" [selected]="selectedFilter_graph_data"
                            (newSelectionEvent)="graphDurationChnage($event,widget)">
                          </app-dropdown>
                        </div>
                        <ng-container *ngIf="widget.filter == 'Custom'">
                          <i class="fa fa-calendar-o" aria-hidden="true"
                            style="right: 195px;font-size: 20px;position: absolute;opacity: 1;top: 6px;">
                            <app-daterangepicker [hide]="true" [InitialDates]="InitialDates"
                              (selectedDates)="customchangeDuration($event)"></app-daterangepicker>
                          </i>
                        </ng-container>
                        <i class="fa fa-download" title="Download data" (click)="download('live', widget.chartname)"></i>
                        <i class="fa fa-arrows-alt" title="show  chart"
                          (click)="open(chartContent, 'CHARTPOPUP', widget)"></i>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ng-container *ngIf="widget.loading">
                      <div style="display: grid; place-items: center;height: 280px;">
                        <app-card-spinner></app-card-spinner>
                      </div>

                    </ng-container>
                    <ng-container *ngIf="!widget.loading && widget.chart_data && widget.chart_data.length > 0">
                      <app-linechart [data]="widget.chart_data"></app-linechart>
                    </ng-container>
                    <ng-container *ngIf="!widget.chart_data || widget.chart_data.length == 0">
                      <div style="
                          text-align: center;
                          margin-top: 20px;
                          color: #130b0b7a;
                        ">
                        {{ error }}
                      </div>
                      <!-- <div class="chartoverlay"></div>
                      <div class="chartspinner"></div> -->
                    </ng-container>
                  </div>
                </div>
                <ng-container *ngIf="widget.category == 'SINGLE_CARD'">
                  <card-single [widgetInfo]="widget"></card-single>
                </ng-container>
                <ng-container *ngIf="widget.category == 'HEADING'">
                  <app-heading [widgetInfo]="widget"></app-heading>
                </ng-container>
                <ng-container *ngIf="widget.category == 'LIST_CARD'">
                  <app-card-list [widgetInfo]="widget"></app-card-list>
                </ng-container>
                <ng-container *ngIf="widget.category == 'GRID_CARD'">
                  <app-card-grid [widgetInfo]="widget"></app-card-grid>
                </ng-container>
                <ng-container *ngIf="widget.category == 'SHIFTWISE'">
                  <app-shiftwise [widgetInfo]="widget"></app-shiftwise>
                </ng-container>
                <ng-container *ngIf="widget.category == 'DATATABLE'">
                  <app-datatable [widgetInfo]="widget"></app-datatable>
                </ng-container>
                <ng-container *ngIf="widget.category == 'DIAGRAM'">
                  <app-diagram [widgetInfo]="widget"></app-diagram>
                </ng-container>

              </div>

            </ng-container>
            </ng-container>

            <ng-container *ngIf="!getWidgetLength('widgetRender')">
              <div style="
              height: calc(100vh - 225px);
              padding: 20px;
              font-size: 18px;
              font-family: 'sfpro-r';
              width: -webkit-fill-available;
            ">
                <app-message [message]="'No Trends Found'"></app-message>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="row" *ngSwitchCase="'Overview'">
          <div class="col-md-5 col-sm-6 col-xs-12 assetGrid">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-9 pad0">
                    <div class="name">{{ selectedAssetData?.customName }}</div>
                    <!-- <div class="mode">Operation mode</div> -->
                    <!-- <div class="action"> -->
                    <!-- <ng-container *ngIf="selectedAssetData?.status == 1"
                        ><img
                          src="assets/images/asset_healthy_icon/healthy.png"
                        />
                        Healthy
                      </ng-container>
                      <ng-container *ngIf="selectedAssetData?.status == 0"
                        ><img
                          src="assets/images/asset_healthy_icon/nothealthy.png"
                        />
                        Not Healthy
                      </ng-container> -->
                    <!-- <button class="btn btn-default"
                                                [ngClass]="{'off':selectedAssetData?.status==0,'on':(selectedAssetData?.status==1 && selectedAssetData?.alert_count==0),'critical':(selectedAssetData?.status==1 && selectedAssetData?.alert_count>0)}">{{selectedAssetData?.mode}}</button> -->
                    <!-- </div> -->
                  </div>
                  <div class="col-sm-3 pad0">
                    <!-- <div
                      class="status"
                      [ngClass]="{
                        off: selectedAssetData?.status == 0,
                        on:
                          selectedAssetData?.status == 1 &&
                          selectedAssetData?.alert_count == 0,
                        critical:
                          selectedAssetData?.status == 1 &&
                          selectedAssetData?.alert_count > 0
                      }"
                    >
                      <div class="value">
                        {{ selectedAssetData?.status == 0 ? "OFF" : "ON" }}
                      </div>
                      <div class="key">Running Status</div>
                    </div> -->
                    <div class="assetalert" *ngIf="
                        selectedAssetData?.status == 1 &&
                        selectedAssetData?.alert_count > 0
                      ">
                      <i class="fa fa-warning"></i>
                      {{ selectedAssetData?.alert_count }} Alert
                    </div>
                  </div>
                </div>
                <div class="col-sm-12" style="padding: 10px 0">
                  <div class="border-bottom"></div>
                </div>
                <div class="col-sm-12 pad0">
                  <img [src]="assetImageSrc" src="assets/images/dashboard/asset_image_large.PNG"
                    style="width: 100%; height: 230px" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-xs-12 statisticsCard" style="display: none">
            <div class="card">
              <div class="cardTitle">Statistics</div>
              <div class="card-body">
                <div *ngFor="let d of progressBarData">
                  <app-progressbar [data]="d"></app-progressbar>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 col-sm-6 col-xs-12 alertCard">
            <div class="card">
              <div class="shift cardTitle">
                <div class="">Alerts ({{ alertData.length }}) (In last 3 days) </div>
                <button type="button" class="btn btn-info" style="
                    background-color: transparent !important;
                    color: #417cf7;
                    border-color: #417cf7 !important;
                  " (click)="navigateToAlerts()">
                  More Alerts
                </button>
              </div>
              <div class="" style="overflow: auto">
                <div class="padding15 border-bottom"
                  *ngFor="let alert of alertData | paginate: { itemsPerPage: 5, currentPage: p}">
                  <div class="timestamp">
                    <ng-container *ngIf="alert.timestamp && alert.timestamp._seconds">
                      <span class="alert_time">Alert Time:</span> {{ alert.timestamp._seconds * 1000 | date: "medium" }}
                    </ng-container>
                    <ng-container *ngIf="alert.timestamp && !alert.timestamp._seconds">
                      <span class="alert_time">Alert Time:</span> {{ alert.timestamp | date: "medium" }}
                    </ng-container>
                  </div>
                  <div class="title">
                    <!-- {{alert.description||"Alert Comes here"}} -->
                    <ng-container *ngIf="alert.value > alert.upper">
                      <span class="alert_description">Alert Description: </span> <b>{{ alert.tag_desc }}</b> has gone
                      above its Upper
                      Threshold of <b style="color: #2A7CF6;">{{ alert.upper }} {{ alert.unit }}</b>.
                      Actual Value is <b style="color: #FF7B7B">{{ alert.value }}</b>
                      {{ alert.unit }}
                    </ng-container>
                    <ng-container *ngIf="alert.lower > alert.value">
                      <span class="alert_description">Alert Description: </span> <b>{{ alert.tag_desc }}</b> has gone
                      below its Lower
                      Threshold of <b style="color: #2A7CF6;">{{ alert.lower }} {{ alert.unit }}</b>.
                      Actual Value is <b style="color:#FF7B7B">{{ alert.value }}</b>
                      {{ alert.unit }}
                    </ng-container>
                    <ng-container *ngIf="
                        alert.value > alert.lower && alert.value < alert.upper
                      ">
                      {{ alert.tag_desc }} - {{ alert.value }} {{ alert.unit }}
                    </ng-container>
                  </div>

                  <div style="width: 100px; margin-top: 5px; display: inline">
                    <label style="margin-right: 10px; font-weight: 500">
                      Acknowledge :
                    </label>
                    <app-dropdown id="ack" [values]="acknowledgeList" [selected]="alert.acknowledge"
                      (newSelectionEvent)="acknowledge($event, alert)">
                    </app-dropdown>
                  </div>
                </div>
                <div class="pagination_card" style="position: absolute; bottom: 0px; height: 34px;"
                  *ngIf="alertData.length && alertData.length > 10">
                  <app-pagination (changePage)="p = $event"></app-pagination>
                </div>
                <div class="text-center" *ngIf="alertData.length == 0" style="
                height: calc(100vh - 380px);
                padding: 20px;
                font-size: 18px;
                font-family: 'sfpro-r';
              ">
                  <app-message [message]="'No Alerts Found'"></app-message>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 shift shif-margin ">
            <div class="text-header" *ngIf="lastUpdated && kpiData.length">
              Last Updated : <span class="text-value">{{ lastUpdated }} (10pm - 10pm)</span>
            </div>
          </div>
          <ng-container *ngFor="let kpi of kpiData">

            <div *ngIf="!kpi.shift" class="col-md-3 col-sm-4 col-xs-6"
              [ngStyle]="{'padding-right.px': kpi.title == 'HFO Consumption'?15:0,'margin-bottom.px': 15}">
              <app-single-card [cardData]="kpi"></app-single-card>
            </div>
          </ng-container>
          <ng-container *ngIf="selectedAssetData?.asset == 'RHF2'">
            <div class="col-md-12 col-sm-12 col-xs-12 shift shif-margin">
              <div class="text-header">
                Previous Shift:
                <span *ngIf="prev_shift" class="text-value">{{
                  prev_shift
                  }}</span>
              </div>
              <div class="text-header">
              </div>
            </div>
            <ng-container *ngFor="let kpi of kpiData">
              <div *ngIf="kpi.shift" class="col-md-3 col-sm-4 col-xs-6"
                [ngStyle]="{'padding-right.px': kpi.title == 'HFO Consumption'?15:0,'margin-bottom.px': 15}">
                <app-single-card [cardData]="kpi"></app-single-card>
              </div>
            </ng-container>
          </ng-container>
          <!-- <ng-container *ngIf="selectedAssetData.asset != 'RHF2'"> -->
          <ng-container
            *ngIf="selectedAssetData.asset == 'BD' || selectedAssetData.asset == 'BD2' || selectedAssetData.asset == 'HOT SAW' || selectedAssetData.asset == 'tandem' || selectedAssetData.asset == 'Straightening Machine'">
            <div class="col-md-12 col-sm-12 col-xs-12 shift shif-margin">
              <div class="text-header">
                Previous Shift:
                <span *ngIf="prev_shift" class="text-value">{{
                  prev_shift
                  }}</span>
              </div>
              <div class="text-header">
              </div>
            </div>
            <ng-container *ngFor="let kpi of kpiData">
              <div *ngIf="kpi.shift" class="col-md-3 col-sm-4 col-xs-6" style="padding-right: 15px;margin-bottom: 15">
                <app-single-card [cardData]="kpi"></app-single-card>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="selectedAssetData.asset == 'BD' || selectedAssetData.asset == 'BD2' ||selectedAssetData.asset == 'tandem'">
            <div class="col-md-12 col-sm-12 col-xs-12 shift shif-margin">
              <div class="text-header">
                Live Data Updated at :
                <span class="text-value">{{ LiveTimestamp
                  }}</span>
              </div>
            </div>
            <ng-container *ngFor="let kpi of liveKpiData">
              <div class="col-md-3 col-sm-4 col-xs-6" style="padding-right: 0px !important;margin-bottom: 8px">
                <app-single-card [cardData]="kpi"></app-single-card>
              </div>
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf=" selectedAssetData.asset == 'RHF2'|| selectedAssetData.asset == 'HOT SAW'||selectedAssetData.asset == 'BD' || selectedAssetData.asset == 'BD2' ||selectedAssetData.asset == 'tandem'">
            <div class="col-md-12 col-sm-12 col-xs-12 shift shif-margin">
              <div class="text-header">
                Production Data:
                <span class="text-value">{{ prod_timestamp
                  }}</span>
              </div>
            </div>
            <ng-container *ngFor="let kpi of productionData">
              <div class="col-md-3 col-sm-4 col-xs-6" style="padding-right: 0px !important;margin-bottom: 8px">
                <app-single-card [cardData]="kpi"></app-single-card>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="sidebarSelected?.tabs && sidebarSelected?.tabs.length > 0">
            <div class="padding10 col-12">
              <div class="card tabCard">
                <div class="tab-wrap">
                  <div class="tab-content" *ngFor="let tab of sidebarSelected.tabs; let i = index"
                    [ngStyle]="{ width: tabsWidth + 'px' }" (click)="navigateToTab(tab, i)">
                    <div class="tab-label-content">
                      <label for="{{ tab.tabId }}">{{ tab.title }} T</label>
                    </div>
                  </div>
                  <div class="slide" [ngStyle]="{
                      width: tabsWidth + 'px',
                      left: tabsWidth * selectedIndex + 'px'
                    }"></div>
                </div>
                <ng-container *ngFor="let tab of sidebarSelected.tabs; let i = index">
                  <ng-container *ngIf="selectedIndex == i">
                    <div class="row">
                      <ng-container *ngFor="let widget of widgetData">

                          <div class="padding10 chartDiv" [ngClass]="widget.width">


                            <div class="card" *ngIf="widget.category == 'CHART'">
                              <div class="cardTitle">
                                <div class="row pos-relative">
                                  <div class="col-sm-5" style="margin:0;padding: 5px">{{ widget.title }}</div>
                                  <div class="col-sm-7 text-right icon  d-flex justify-content-end align-items-center">
                                    <!-- <ng-container *ngIf="selectedFilter == 'Live'">
                                  <i
                                    class="fa fa-play"
                                    *ngIf="!widget.live"
                                    title="Play live data"
                                    (click)="widget.live = !widget.live"
                                  ></i>
                                  <i
                                    class="fa fa-pause"
                                    title="Pause live data"
                                    *ngIf="widget.live"
                                    (click)="widget.live = !widget.live"
                                  ></i>
                                </ng-container> -->
                                    <div class="padding-right-10p small-dropdown">
                                      <app-dropdown [values]="filterList" [selected]="selectedFilter"
                                        (newSelectionEvent)="graphDurationChnage($event,widget)">
                                      </app-dropdown>
                                    </div>
                                    <ng-container *ngIf="widget.filter == 'Custom'">
                                      <i class="fa fa-calendar-o" aria-hidden="true"
                                        style="right: 195px;font-size: 20px;position: absolute;opacity: 1;top: 6px;">
                                        <app-daterangepicker [hide]="true" [InitialDates]="InitialDates"
                                          (selectedDates)="customchangeDuration($event)"></app-daterangepicker>
                                      </i>
                                    </ng-container>
                                    <i class="fa fa-download" title="Download data"
                                      (click)="download('live', widget.chartname)"></i>
                                    <i class="fa fa-arrows-alt" title="show  chart" (click)="
                                    open(chartContent, 'CHARTPOPUP', widget)
                                  "></i>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <ng-container *ngIf="widget.loading">
                                  <div style="display: grid; place-items: center;height: 280px;">
                                    <app-card-spinner></app-card-spinner>
                                  </div>

                                </ng-container>
                                <ng-container *ngIf="
                              !widget.loading &&
                                widget.chart_data &&
                                widget.chart_data.length > 0
                              ">
                                  <app-linechart [data]="widget.chart_data"></app-linechart>
                                </ng-container>
                              </div>
                            </div>
                            <ng-container *ngIf="widget.category == 'SINGLE_CARD'">
                              <card-single [widgetInfo]="widget"></card-single>
                            </ng-container>
                            <ng-container *ngIf="widget.category == 'HEADING'">
                              <app-heading [widgetInfo]="widget"></app-heading>
                            </ng-container>
                            <ng-container *ngIf="widget.category == 'LIST_CARD'">
                              <app-card-list [widgetInfo]="widget"></app-card-list>
                            </ng-container>
                            <ng-container *ngIf="widget.category == 'GRID_CARD'">
                              <app-card-grid [widgetInfo]="widget"></app-card-grid>
                            </ng-container>
                            <ng-container *ngIf="widget.category == 'SHIFTWISE'">
                              <app-shiftwise [widgetInfo]="widget"></app-shiftwise>
                            </ng-container>
                            <ng-container *ngIf="widget.category == 'DIAGRAM'">
                              <app-diagram [widgetInfo]="widget"></app-diagram>
                            </ng-container>

                          </div>

                      </ng-container>

                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <!-- <div
            class="padding10 chartDiv"
            [ngClass]="widget.width"
            *ngFor="let widget of widgetData"
          >
            <div class="card" *ngIf="widget.category == 'CHART'">
              <div class="cardTitle">
                <div class="row">
                  <div class="col-sm-8">{{ widget.title }}</div>
                  <div class="col-sm-4 text-right icon">
                    <ng-container *ngIf="selectedFilter == 'Live'">
                      <i
                        class="fa fa-play"
                        *ngIf="!widget.live"
                        title="Play live data"
                        (click)="widget.live = !widget.live"
                      ></i>
                      <i
                        class="fa fa-pause"
                        title="Pause live data"
                        *ngIf="widget.live"
                        (click)="widget.live = !widget.live"
                      ></i>
                    </ng-container>
                    <i
                      class="fa fa-download"
                      title="Download data"
                      (click)="download('live', widget.chartname)"
                    ></i>
                    <i
                      class="fa fa-arrows-alt"
                      title="show  chart"
                      (click)="open(chartContent, 'CHARTPOPUP', widget)"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="pos-relative">
                <ng-container
                  *ngIf="widget.chart_data && widget.chart_data.length > 0"
                >
                  <app-linechart [data]="widget.chart_data"></app-linechart>
                </ng-container>
                <ng-container
                  *ngIf="!widget.chart_data || widget.chart_data.length == 0"
                >
                  <div class="chartoverlay"></div>
                  <div class="chartspinner"></div>
                </ng-container>
              </div>
            </div>
            <ng-container *ngIf="widget.category == 'SINGLE_CARD'">
              <card-single [widgetInfo]="widget"></card-single>
            </ng-container>
            <ng-container *ngIf="widget.category == 'HEADING'">
              <app-heading [widgetInfo]="widget"></app-heading>
            </ng-container>
            <ng-container *ngIf="widget.category == 'LIST_CARD'">
              <app-card-list [widgetInfo]="widget"></app-card-list>
            </ng-container>
            <ng-container *ngIf="widget.category == 'GRID_CARD'">
              <app-card-grid [widgetInfo]="widget"></app-card-grid>
            </ng-container>
            <ng-container *ngIf="widget.category == 'DATATABLE'">
              <app-datatable [widgetInfo]="widget"></app-datatable>
            </ng-container>
            <ng-container *ngIf="widget.category == 'DIAGRAM'">
              <app-diagram [widgetInfo]="widget"></app-diagram>
            </ng-container>
          </div> -->
        </div>
        <div *ngSwitchCase="'KPI Monitor'">
          <app-kpi *ngIf="details_kpi" [values]="details_kpi"></app-kpi>
        </div>
        <div *ngSwitchCase="'Status'">
          <app-status [selectedValues]="details_kpi"></app-status>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #content let-modal style="padding: 80px;margin-right: 300px !important;">
  <div class="modal-content" style="width: 120%; margin-left: -65px;">

    <div class="modal-header">
      <h6 class="modal-title" id="modal-basic-title">Widget Manager</h6>
      <!-- <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button> -->
      <i class="fa fa-times" aria-hidden="true" (click)="closePopup()"></i>
    </div>
    <div class="modal-body widgetPopupBody">
      <div class="row widgetPopupContent">
        <div class="col-md-12 widgetList">
          <div class="row widgetListTitle">
            <div class="col-6"></div>
            <div class="col-5 screen padding0" *ngIf="sidebarDropdown.length > 0"><span style="
            display: flex;
            position: relative;
            top: 4px;
            left: 75px;">Widget Created ({{ getWidgetLength('widgetConfig')}})</span>
              <app-dropdown [values]="sidebarDropdown" [selected]="sidebarDropdownSelected"
                (newSelectionEvent)="sidebarDropdownChange($event)" [displayMember]="'label'" type="object">
              </app-dropdown>
            </div>

            <div *ngIf="widgetListData.length <= 0" style="
      height: calc(100vh - 350px);
      padding: 20px;
      font-size: 18px;
      font-family: 'sfpro-r';
      text-transform: capitalize;
      width: -webkit-fill-available;
      position: relative;
      right: 8px;
    ">
              <app-widget-message [message]="'Add Widgets to Monitor'"></app-widget-message>
            </div>
          </div>
          <div *ngIf="widgetListData.length > 0">
            <ul cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
              <ng-container *ngFor="let widget of widgetListData; let idx = index">
                <ng-container
              *ngIf="!widget.userSpecific || (widget.userSpecific && widget.userSpecific == 'Current User' && widget.email==this.loggedinUser.email)">
                  <li class="widget example-box" cdkDrag>
                    <div class="row">
                      <div class="col-md-10">
                        <div class="chartTitle">
                          <span class="badge badge-info">{{ widget.category }}</span>{{ widget.title }}
                        </div>
                        <div class="chartTags" [innerHTML]="tagDisplay(widget)"></div>
                      </div>
                      <div class="col-md-2 listAction">
                        <i class="fa fa-pencil cursor-pointer" title="Edit"
                          (click)="widgetAction(widget, 'EDIT')"></i>&nbsp;
                        <i class="fa fa-trash cursor-pointer" title="Delete"
                          (click)="widgetAction(widget, 'DELETE')"></i>&nbsp;
                      </div>
                    </div>
                  </li>
                </ng-container>
              </ng-container>

            </ul>
          </div>
        </div>
        <div class="col-md-12 widgetInput">
          <!-- <div class="w-100">
          <label>Widget Title</label>
          <input type="text" [(ngModel)]="addWidgetObj.title" class="w-100" />
        </div> -->
          <!-- <div class="w-100">
          <label>Widget Width</label>
          <app-dropdown
            [values]="widthList.data"
            [selected]="widthList.selected"
            [displayMember]="'width'"
            (newSelectionEvent)="widthChange($event)"
            [type]="'object'"
          >
          </app-dropdown>
        </div> -->
          <!-- <div class="w-100">
          <label>Widget Category</label>
          <app-dropdown
            [values]="widgetCategory.data"
            [selected]="widgetCategory.selected"
            (newSelectionEvent)="widgetCategoryChange($event)"
            [displayMember]="'name'"
            type="object"
          >
          </app-dropdown>
        </div> -->
          <div class="card card-1">
            <div class="row">
              <div class="col-md-3">
                <div style="display: grid">
                  <label>Widget Title</label>
                  <input type="text" [(ngModel)]="addWidgetObj.title" class="w-100" />
                </div>
              </div>
              <div class="col-md-3">
                <div style="display: grid">
                  <label>Widget Width</label>
                  <app-dropdown [values]="widthList.data" [selected]="widthList.selected" [displayMember]="'width'"
                    (newSelectionEvent)="widthChange($event)" [type]="'object'">
                  </app-dropdown>
                </div>
              </div>
              <div class="col-md-3">
                <div style="display: grid">
                  <label>Widget Category</label>
                  <app-dropdown [values]="widgetCategory.data" [selected]="widgetCategory.selected"
                    (newSelectionEvent)="widgetCategoryChange($event)" [displayMember]="'name'" type="object">
                  </app-dropdown>
                </div>
              </div>
              <div class="col-md-3" *ngIf="(addWidgetObj.query_action=='insert' || (addWidgetObj.query_action=='update' && addWidgetObj.createdBy == loggedinUser.email))">
                <div style="display: grid">
                  <label>User Specific </label>
                  <app-dropdown [values]="userSpecific.data" [selected]="userSpecific.selected"
                    (newSelectionEvent)="sidebarUserSpecificChange($event)" type="string">
                  </app-dropdown>
                </div>
              </div>


            </div>
          </div>
          <ng-container *ngIf="
            widgetCategory.selected &&
            widgetCategory.selected.id == 'SINGLE_CARD'
          ">
            <div class="w-100">
              <div><label title="Select Tag">Select Tag</label></div>
              <app-searchable-dropdown [values]="tagData" [displayMember]="'component'"
                [selectedData]="selectedAssetWidgetTags" (newSelectionEvent)="changeAssetTag($event, 'single')"
                [isMultiple]="false">
              </app-searchable-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="
            widgetCategory.selected &&
            (widgetCategory.selected.id == 'LIST_CARD' ||
              widgetCategory.selected.id == 'GRID_CARD')
          ">
            <div class="tagMapping">
              <div class="row">
                <div class="col-9 mainTitle">Tag Mapping</div>
                <div class="col-3 addBtnArea">
                  <button type="button" class="btn btn-primary" (click)="addTagMapping()">
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>

              <div class="row">
                <!-- <div class="col-5 tagMapLabel font-size-14p">Label</div>
                                <div class="col-6 tagMapTag font-size-14p">Tag</div>
                                <div class="col-1 tagMapAction"></div> -->

                <ng-container *ngFor="
                  let item of addWidgetObj.options.tagMapping;
                  let pointIndex = index
                ">
                  <!-- <div class="col-5 tagMapLabel"><input  class="wid100" [(ngModel)]=item.label/></div>
                                    <div class="col-6 tagMapTag">
                                        <app-searchable-dropdown [values]="tagData" [displayMember]="'component'"
                                    [selected]="item.tag"
                                    (newSelectionEvent)="changeAssetTag($event,'single')" [isMultiple]="false">
                                </app-searchable-dropdown>
                                    </div> -->

                  <div class="col-12 padding0 fsize14">
                    <div class="row">
                      <div class="col-6 padLeft0">Param {{ pointIndex + 1 }}</div>
                      <div class="col-6 tagMapAction text-right">
                        <button type="button" class="btn btn-primary" (click)="deleteTagMapping(pointIndex)">
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>

                    <div class="row tagMapLabel">
                      <div class="col-2 padLeft0">Label</div>
                      <div class="col-10 padding0">
                        <input class="wid100" [(ngModel)]="item.label" />
                      </div>
                    </div>
                    <div class="row tagMapTag">
                      <div class="col-2 padLeft0">Tag</div>
                      <div class="col-10 padding0">
                        <app-searchable-dropdown [values]="tagData" [displayMember]="'component'"
                          [selectedData]="item.tag" (newSelectionEvent)="
                          changeWidgetTag($event, 'single', pointIndex)
                        " [isMultiple]="false">
                        </app-searchable-dropdown>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

              <!-- <div class="w-100">
                                <div><label>Select Tag</label></div>
                                <app-searchable-dropdown [values]="tagData" [displayMember]="'component'"
                                    [selected]="selectedAssetWidgetTags"
                                    (newSelectionEvent)="changeAssetTag($event,'multiple')" [isMultiple]="true">
                                </app-searchable-dropdown>
                            </div> -->
            </div>
          </ng-container>
          <ng-container *ngIf="
            widgetCategory.selected && widgetCategory.selected.id == 'GRID_CARD'
          ">
            <div class="w-100">
              <div><label>Grid Column</label></div>
              <input type="number" [(ngModel)]="addWidgetObj.options.gridColumn" class="w-100" min="1" max="3" />
            </div>
          </ng-container>
          <ng-container *ngIf="
            widgetCategory.selected && widgetCategory.selected.id == 'LIST_CARD'
          ">
            <div class="w-100">
              <div><label>Display Type</label></div>
              <app-dropdown [values]="listDisplayTyes.data" [selected]="addWidgetObj.options?.listDisplayType"
                (newSelectionEvent)="
                widgetOptionChange($event, 'listDisplayType')
              ">
              </app-dropdown>
            </div>
          </ng-container>

          <ng-container *ngIf="
            widgetCategory.selected && widgetCategory.selected.id == 'DIAGRAM'
          ">
            <div class="w-100">
              <div><label>Diagram Category</label></div>
              <app-dropdown [values]="diagramCategory.data" [selected]="addWidgetObj.options?.diagramCategory"
                (newSelectionEvent)="
                widgetOptionChange($event, 'diagramCategory')
              ">
              </app-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="
            widgetCategory.selected && widgetCategory.selected.id == 'SHIFTWISE'
          ">
            <div class="w-100">
              <div><label>Shiftwise Category</label></div>
              <app-dropdown [values]="shiftwiseCategory.data" [selected]="addWidgetObj.options?.shiftwiseCategory"
                (newSelectionEvent)="
                widgetOptionChange($event, 'shiftwiseCategory')
              ">
              </app-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="
            widgetCategory.selected && widgetCategory.selected.id == 'CHART'
          ">
          <div class="row" >
            <div class="col-6">
              <div><label>Chart Type</label></div>
              <app-dropdown [values]="chartType.data"
                [selected]="chartType.selected" (newSelectionEvent)="changeChartType($event)"
               >
              </app-dropdown>
            </div>

              <div class="col-6">
                <div><label>Select Tag</label></div>
                <app-searchable-dropdown [values]="tagData" [displayMember]="'component'" [maxTags]="10"
                  [selectedData]="selectedAssetWidgetTags" (newSelectionEvent)="changeAssetTag($event, 'multiple')"
                  [isMultiple]="true">
                </app-searchable-dropdown>
              </div>
          </div>


            <form [formGroup]="widgetForm" novalidate>
              <div formGroupName="formulas">
                <label>Add KPI formula</label>
                <div class="row">
                  <div class="col-md-8"></div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <span class="note">Note : Tag Names</span>
                    <span class="pL-10 note" *ngFor="let tag of selectedAssetWidgetTags; let t = index">
                      {{ tag.tag }},
                    </span>
                  </div>
                </div>
                <div formArrayName="PRE">
                  <div class="row" *ngFor="let phase of formulaArray.controls; let i = index" [formGroupName]="i">
                    <div class="col-md-8 pR-0">
                      <input type="text" class="wid100" formControlName="axisName" placeholder="Name" />
                    </div>
                    <div class="col-md-4">
                      <input type="text" class="wid100" formControlName="unit" placeholder="Unit" />
                    </div>
                    <div class="col-md-12 pT-10">
                      <input type="text" class="wid100" formControlName="formula" placeholder="Formula" />
                    </div>
                  </div>
                </div>
              </div>

            </form>

          </ng-container>
          <div class="modal-footer">
            <button type="button" class="btn btn-info" (click)="modal.dismiss('Cross click')"> &nbsp;<span
                style="color: #ffff; font-size: 15px; font-family: 'sfpro-r'">Close</span>
            </button>
            <button type="button" class="btn btn-info" (click)="addWidget('viewlist')" style="font-size: 15px;">
              <ng-container *ngIf="widgetButton == 'ADD'">
                <i class="fa fa-plus"></i> Add New Widget
              </ng-container>
              <ng-container *ngIf="widgetButton == 'EDIT'">
                <i class="fa fa-save"></i> <span style="padding-left: 10px;">Save</span>
              </ng-container>
            </button>
            <!-- <button
            type="submit"
            class="btn btn-info"
            (click) = "addWidget()"
            *ngIf="widgetButton == 'ADD'"
          > &nbsp;<span style="color: #ffff; font-size: 15px; font-family: 'sfpro-r'">Save</span>
          </button> -->
          </div>
        </div>
      </div>
    </div>

  </div>

</ng-template>

<ng-template #chartContent let-modal>
  <div class="modal-header">
    <h6 class="modal-title" id="modal-basic-title">
      {{ selectedPopupChart.title }}
    </h6>

    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <ng-container *ngIf="
            selectedPopupChart.chart_data &&
            selectedPopupChart.chart_data.length > 0
          ">
          <app-linechart [data]="selectedPopupChart.chart_data"></app-linechart>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
