import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, LocationStrategy, APP_BASE_HREF } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { PageComponent } from './components/page/page.component';
import { ForgotComponent } from './components/forgot/forgot.component';

import { StoreModule } from '@ngrx/store';
import { userReducer } from './reducers/user.reducer';

import { CommonComponentsModule } from './components/common/common.module';
import { CommonPageModule } from './components/page/page.module';
import { CommonComponentPageModule } from './components/page/common/common.module';
import {
  AngularFireStorageModule,
  AngularFireStorage,
} from '@angular/fire/storage';
import { HttpService } from './services/http.service';
import { ErrorDialogService } from './services/errordialog.service';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { SortPipe } from './Pipes/sort.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
// import { AskaiComponent } from './components/askai/askai.component';
// import { LogsComponent } from './components/askai/logs/logs.component';

// import { NgxBLPLoginModule } from 'ngx-blplogin';
import { NgxBlpConversationAIModule } from 'ngx-blp-conversation-ai';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageComponent,
    ForgotComponent,
    // AskaiComponent,
    // LogsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    RouterModule.forRoot([]),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule.forRoot({ user: userReducer }),
    CommonComponentsModule,
    CommonComponentPageModule,
    CommonPageModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxBlpConversationAIModule,
    // NgxBLPLoginModule
  ],
  providers: [
    HttpService,
    ErrorDialogService,
    SortPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    { provide: APP_BASE_HREF, useValue: '/' },
  ],
  exports: [
    CommonComponentsModule,
    CommonPageModule,
    // AskaiComponent,
    // LogsComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
