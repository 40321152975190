import { Injectable,OnInit ,AfterViewInit} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ExportToCsv } from 'export-to-csv';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import {environment} from './../../environments/environment';

import moment from 'moment';
import { where } from 'sequelize/types';
import { promise, utils } from 'protractor';
import { reject } from 'lodash';
import { Router } from '@angular/router';
import { UtilService } from './util.service';
import * as async from 'async'
import _ from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  //userRef: AngularFirestoreCollection<UserData> | undefined;
  //collection_url = environment.firestore_collection_url;
  // domain = "https://datastore.ai-works.in:9331"
  domain = 'https://datastore.orionintelligentenergy.com/';
  bigTablegetApi: any =
    'https://bigquery-api-server-npkusg2a5a-uc.a.run.app/select/';
  // cloudFuncUrl = environment.cloudFunctionUrl;
  cloudFuncUrl = environment.cloudFunctionUrl;
  // cloudFuncUrl = environment.testFunction
  analyticsUrl =
    'https://asia-south1-jspl-355706.cloudfunctions.net/PredictAI_JSPL/getAnalyticsData';
  // analyticsUrl =
  //   'http://192.168.1.123:7000/getAnalyticsData';
  httpHeaders: any;
  httpOptions: any;
  options: any = {};
  getDataUrl: string = this.cloudFuncUrl + 'getData';
  getHistoryDataUrl: string = this.cloudFuncUrl + 'getHistoryData';
  updateDataUrl: string = this.cloudFuncUrl + 'updateData';
  deleteDataUrl: string = this.cloudFuncUrl + 'deleteData';
  insertDataUrl: string = this.cloudFuncUrl + 'insertData';
  customerId: string = 'JSPL';
  // historyAPI ='https://asia-south1-las-prod-1.cloudfunctions.net/PredictAI_JSPL/getHistoryData';
  historyAPI =
    'https://asia-south1-jspl-355706.cloudfunctions.net/PredictAI_JSPL/getHistoryData';
  // historyAPI='http://192.168.1.123:7000/getHistoryData'
  localApiurl = environment.api_endpoint;
  domainUrl: any = environment.aggregationURL;
  csvoptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };
  gen_ai_api =
    'https://asia-south1-blp-iai.cloudfunctions.net/JSPL_BigQuery_GenAI';
  aggregationHeader: any = new HttpHeaders();
  tokesn: any = {};
  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private router: Router,
    private util: UtilService
  ) {
    if (this.tokesn && this.tokesn != undefined) {
      this.aggregationHeader = {
        Headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.tokesn['access']}`,
        }),
      };
    } else {
      this.aggregationHeader = {
        Headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
    }
    // let params = {
    //   collectionName: "Spectrometer_History",
    //   conditions:[
    //     {key:"department",symbol:"in", value:["sms_3", 'sms_2']},
    //     {key:"timestamp",symbol:">=", value:moment('28-03-2023',"DD-MM-YYYY").startOf('day').toDate()},
    //     {key:"timestamp",symbol:"<=", value:moment('29-03-2023',"DD-MM-YYYY").endOf('day').toDate()},
    //   ],
    //   orderBy:'timestamp'
    // }
    // console.log(params)
    // this.getSPectrometerData(params).subscribe(res =>{
    //   let data:any =[]
    //   res.forEach((ele:any) =>{
    //     data.push(ele.data())
    //   })
    //   this.getCsv(data)
    //   // console.log(data)
    // })
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa('jspluser@blpadmin.co.in:uYwNsFVBp@*$'),
      }),
    };
  }
  // ngOnInit(): void {
  //   this.util.getToken().subscribe((result:any)=>{
  //     if(result && result!=undefined){
  //       this.tokesn=result
  //      }
  //      else{
  //       this.tokesn=localStorage.getItem(`tokens`)
  //        this.tokesn=JSON.parse(this.tokesn)
  //      }
  //       this.aggregationHeader={Headers:new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${this.tokesn['access']}`
  //       })}

  //   })
  // }
  getCsv(list: any[]) {
    let finalResult: any = [];
    list.forEach((data) => {
      let obj: any = {
        ZDATE: moment(data.timestamp.seconds * 1000).format('DD-MM-YYYY'),
        TIME: moment(data.timestamp.seconds * 1000).format('HH:mm:ss'),
        REMARK1: data.remarks1 ?? '',
        REMARK2: data.remarks2 ?? '',
        RESERVE1: data.reserve1 ?? '',
        RESERVE2: data.reserve2 ?? '',
        RESERVE3: '',
        RESERVE4: '',
        RESERVE5: '',
        SAP_SAVED: data.isSavedToSAP,
      };
      if (data.department == 'sms_2' || data.department == 'sms_3') {
        obj = {
          ...obj,
          OPERATION: data.operationNo ?? '',
          INSPECTION_POINT: data.inspectionPoint ?? '',
          CHARG: data.batchHeatNo ?? '',
          PRUEFLOS: data.batchHeatNo ?? '',
          MACHINE_INFO: data.machineInfo ?? '',
        };
      } else {
        if (data.department == 'in_process') {
          obj = {
            ...obj,
            PRUEFLOS: Number(data.lotNo) ?? '',
            HERKUNFT: '13',
            INSPECTION_POINT: data.inspectionPoint ?? '',
            OPERATON: data.operationNo ?? '',
            WERKS: data.plant ?? '',
            MATNR: data.material ?? '',
            INSPECTOR: data.inspector ?? '',
          };
        } else if (data.department == 'post_manufacturing') {
          obj = {
            ...obj,
            CHARG: data.heat ?? '',
            HERKUNFT: '04',
            INSPECTION_POINT: data.inspectionPoint ?? '',
            WERKS: data.plant ?? '',
            MATNR: data.material ?? '',
            INSPECTOR: data.inspector ?? '',
          };
        } else {
          obj = {
            ...obj,
            GENO: data.gateEntryNo ?? '',
            MATNR: data.material ?? '',
            OPERATION: data.operationNo ?? '',
            HERKUNFT: '01',
            WERKS: data.plant ?? '',
            INSPECTOR: data.inspector ?? '',
          };
        }
      }
      let char = Array.from({ length: 40 }, (_, i) => i + 1);
      char.forEach((no, index) => {
        let elementName = `CHAR${index + 1}`;
        let elementValue = `CHAR${index + 1}_VALUE`;
        if (data && data.chemicals && data.chemicals[index]) {
          obj[elementName] = data.chemicals[index].title;
          obj[elementValue] = data.chemicals[index].value;
        } else {
          obj[elementName] = '';
          obj[elementValue] = '';
        }
      });
      finalResult.push(obj);
    });
    this.csvexport({
      csv: finalResult,
      title: 'Spectrometer SAP DATA',
      filename: 'SpectroData_' + moment().format('DD-MM-YYYY HH:mm:ss'),
    });
    console.log(finalResult);
  }
  csvexport(data) {
    if (data) {
      this.csvoptions['title'] = data.title;
      this.csvoptions['filename'] = data.filename;
      const csvExporter = new ExportToCsv(this.csvoptions);
      csvExporter.generateCsv(data.csv);
    }
  }
  updateAssetTime(params) {
    return this.http.post(
      this.cloudFuncUrl + 'updateTimestampInAsset',
      params,
      this.httpOptions
    );
  }
  fireStoreDocInsert(category: string, data: any) {
    let insertData = { data: data, category: category };
    return this.http.post(this.insertDataUrl, insertData, this.httpOptions);
  }
  fireStoreDocUpdate(category: string, id, data: any) {
    let updateData = { id: id, data: data, category: category };
    return this.http.post(this.updateDataUrl, updateData, this.httpOptions);
  }
  fireStoreDocDelete(category: string, id) {
    let deleteData = { id: id, category: category };
    return this.http.post(this.deleteDataUrl, deleteData, this.httpOptions);
  }
  passwordUpdate(updateData: any) {
    return this.http.post(
      this.cloudFuncUrl + 'updatePassword',
      updateData,
      this.httpOptions
    );
  }
  createUser(data: any) {
    data.customerId = this.customerId;
    return this.http.post(
      this.cloudFuncUrl + 'createUser',
      data,
      this.httpOptions
    );
  }
  getData(params: any) {
    if (params.category && params.category === 'HISTORY') {
      // if (params.aggregation && (params.aggregation == "Live" || params.aggregation === "localHistory")) {
      //   params.collection = "HistoryData";
      //   return this.http.post(`${this.localApiurl}api/history/get`, params,this.httpOptions);
      // }
      if (
        params.aggregation &&
        (params.aggregation == 'Aggregate10MinuiteData' ||
          params.aggregation == 'Aggregate60MinuiteData' ||
          params.aggregation == 'Aggregate1440MinuiteData')
      ) {
        if (params.conditions && params.conditions.length > 0) {
          params.conditions = JSON.stringify(params.conditions);
        }
        return this.http.post(this.getDataUrl, params, this.httpOptions);
      } else {
        /* Bigtable query code here*/
        let data = {
          customerId: 'BLP',
          assetcode: params.assetcode,
          asset: params.asset,
          aggregateFrame: params.aggregateFrame,
          startTime: moment(params.startDate).unix(),
          endTime: moment(params.endDate).unix(),
        };
        return this.http.post(this.domain + 'api/bigtable/select', data, {
          headers: this.httpOptions,
        });
        // return this.http.post(this.bigTablegetApi, data, {
        //   headers: this.httpOptions,
        // });
      }
    } else {
      if (params.conditions && params.conditions.length > 0) {
        params.conditions = JSON.stringify(params.conditions);
      } else if (params.conditions && params.conditions.length == 0) {
        delete params.conditions;
      }
      return this.http.post(this.getDataUrl, params, this.httpOptions);
    }
  }
  getAnalyticsData(params) {
    return this.http.post(this.analyticsUrl, params, this.httpOptions);
  }
  saveWidgetOrder(params) {
    return this.http.post(
      this.cloudFuncUrl + 'saveWidgetOrder',
      params,
      this.httpOptions
    );
  }
  // getUserDetail(params) {
  //   return this.fireStore.collection(`${this.collection_url}/users`, (ref) =>
  //     ref.where("userid", "==", params.userid)
  //   ).get();
  // }
  saveEquipment(data: any) {
    return this.http.post(this.cloudFuncUrl + 'saveEquipment', data, {
      headers: new HttpHeaders({
        enctype: 'multipart/form-data',
        Authorization: 'Basic ' + btoa('jspluser@blpadmin.co.in:uYwNsFVBp@*$'),
      }),
    });
  }
  deleteEquipment(data: any) {
    return this.http.post(
      this.cloudFuncUrl + 'deleteEquipment',
      data,
      this.httpOptions
    );
  }

  customDocumentUpload(data: any) {
    return this.http.post(
      this.cloudFuncUrl + 'documentUpload',
      data,
      this.httpOptions
    );
  }
  customDocumentDelete(data: any) {
    return this.http.post(
      this.cloudFuncUrl + 'deleteUploadedDocument',
      data,
      this.httpOptions
    );
  }
  getLiveData(params) {
    return this.db
      .collection('/Asset_OEE/JSPL/LiveData/', (ref) =>
        ref.where('assetcode', '==', params.assetcode)
      )
      .valueChanges();
  }
  getStaticLiveData(params) {
    return this.db
      .collection('/Asset_OEE/JSPL/LiveData/', (ref) =>
        ref.where('assetcode', '==', params.assetcode)
      )
      .get();
  }
  getHistoryData(params: any) {
    if (params.category == 'HISTORY') {
      return this.http.post(this.domain + 'api/bigtable/select', params, {
        headers: this.httpOptions,
      });
      // return this.http.post(this.bigTablegetApi, params, {
      //   headers: this.httpOptions,
      // });
    } else {
      return this.http.post(this.historyAPI, params, this.httpOptions);
    }
  }
  getAgrregatedData(body: any) {
    return new Promise((resolve, reject) => {
      this.tokesn = localStorage.getItem(`tokens`);
      this.tokesn = JSON.parse(this.tokesn);
      let refreshTokenParan: any = { refresh: this.tokesn['refresh'] };
      let requestBody: any = { ...body };
      delete requestBody['collection'];
      if (body.collection != 'HISTORY') {
        return this.http
          .post(
            `${this.domainUrl}/aggregatedData/`,
            requestBody,
            this.aggregationHeader
          )
          .subscribe(
            (response: any) => {
              if (response['status'] == 'success') {
                resolve(response);
              } else {
                resolve(response);
              }
            },
            (err: any) => {
              this.getRefreshToken(refreshTokenParan)
                .then((rsult: any) => {
                  this.getAgrregatedData(body);
                })
                .catch((err) => {
                  this.util.setLoaderValue(false);
                });
            }
          );
      } else {
        return this.http
          .post(
            `${this.domainUrl}/historyData/`,
            requestBody,
            this.aggregationHeader
          )
          .subscribe(
            (response: any) => {
              if (response['status'] == 'success') {
                resolve(response);
              } else {
                resolve(response);
              }
            },
            (err: any) => {
              this.getRefreshToken(refreshTokenParan)
                .then((rsult: any) => {
                  this.getAgrregatedData(body);
                })
                .catch((err) => {
                  this.util.setLoaderValue(false);
                });
            }
          );
      }
    });
  }
  getLiveKPI(params) {
    let docRef = this.db.collection(
      '/JSPL_Analytics/JSPL_Railmill/JSPL_RUBM',
      (ref) =>
        ref
          .where('assetcode', '==', params.conditions[0].value)
          .where('type', '==', params.conditions[1].value)
          .orderBy('Date', 'desc')
          .limit(1)
    );
    return docRef.valueChanges();
  }
  getRoleSettings(params) {
    let docRef = this.db.collection('/Asset_OEE/JSPL/users/', (ref) =>
      ref.where('email', '==', params.email)
    );
    return docRef.snapshotChanges();
  }

  /********************JSPL PHASE 2************************ */
  getChargingSheetData(params?: any) {
    if (params.limit == 1) {
      return this.db
        .collection(`/Asset_OEE/JSPL/Charging/Sheet/${params.sheetNo}`, (ref) =>
          ref.orderBy('timestamp', 'desc').limit(params.limit)
        )
        .get();
    } else {
      return this.db
        .collection(`/Asset_OEE/JSPL/Charging/Sheet/${params.sheetNo}`, (ref) =>
          ref.where('PLNUM', '==', params.planNum)
        )
        .get();
    }
  }
  getCampaignPlanData(params?: any) {
    return this.db
      .collection(`/Asset_OEE/JSPL/Campaign/Plan/${params.planNo}`)
      .get();
  }
  update(sheetNo) {
    this.db
      .collection(`/Asset_OEE/JSPL/Charging/Sheet/${sheetNo}`)
      .get()
      .subscribe((data) => {
        data.forEach((ele: any) => {
          this.db
            .collection(`/Asset_OEE/JSPL/Charging/Sheet/${sheetNo}`)
            .doc(ele.id)
            .update({ PLNUM: Number(ele.data().PLNUM) })
            .then(() => {
              // console.log('updated')
            });
        });
      });
  }
  getRailMillTarget(params?: any) {
    return this.db
      .collection(
        `/Asset_OEE/JSPL/Railmill Target/${params.frequency}/${params.year}`
      )
      .get();
  }
  updateRailMillTarget(params?: any, body?: any) {
    return this.db
      .collection(
        `/Asset_OEE/JSPL/Railmill Target/${params.frequency}/${params.year}`
      )
      .doc(params.id)
      .update(body);
  }
  getProductionData(params?: any) {
    return this.db
      .collection(`/Production_data`, (ref) =>
        ref
          .where('Date', '>=', params.startDate)
          .where('Date', '<=', params.endDate)
      )
      .valueChanges();
  }
  getDispatch(params?: any) {
    return this.db
      .collection(`/Asset_OEE/JSPL/Dispatch/${params.frequency}/${params.year}`)
      .get();
  }
  updateDispatch(params?: any, body?: any) {
    return this.db
      .collection(`/Asset_OEE/JSPL/Dispatch/${params.frequency}/${params.year}`)
      .doc(params.id)
      .update(body);
  }
  // Spectrometer
  getSpectrometerLiveData(params) {
    return this.db
      .collection('/Asset_OEE/JSPL/Spectrometer_Live', (ref) =>
        ref.where('spectrometerId', '==', params.spectrometerId)
      )
      .valueChanges();
  }
  addHistorySpectrometer(params) {
    return this.db
      .collection('/Asset_OEE/JSPL/Spectrometer_History')
      .add(params.data);
  }
  addDummyLive(docId, data) {
    return this.db
      .collection('/Asset_OEE/JSPL/Spectrometer_Live')
      .doc(docId)
      .set(data);
  }
  updateLiveSpectrometer(params) {
    return this.db
      .collection('/Asset_OEE/JSPL/Spectrometer_Live')
      .doc(params.id)
      .update(params.data);
  }
  updateHistoryById(params: any) {
    return this.db
      .collection('/Asset_OEE/JSPL/Spectrometer_History')
      .doc(params.id)
      .update(params.data);
  }
  getSpectrometerHistoryData(params) {
    return this.db
      .collection('/Asset_OEE/JSPL/Spectrometer_History', (ref) => {
        let query: any = ref;
        if (!params.typeSearch) {
          query = query.where('spectrometerId', '==', params.spectrometerId);
          query = query.where('timestamp', '>=', params.startDate);
          query = query.where('timestamp', '<=', params.endDate);
        } else if (params.typeSearch == 'Date') {
          query = query.where('spectrometerId', '==', params.spectrometerId);
          query = query.where('timestamp', '>=', params.startDate);
          query = query.where('timestamp', '<=', params.endDate);
          query = query.where('machineInfo', '==', params.machineInfo);
        } else {
          query = query.where('spectrometerId', '==', params.spectrometerId);
          query = query.where('batchHeatNo', '==', params.heatNo);
        }
        if (params.inspectionPoint) {
          query = query.where('inspectionPoint', '==', params.inspectionPoint);
        }
        return query;
      })
      .valueChanges();
  }
  getHistorySpectroById(params) {
    return this.db
      .collection('/Asset_OEE/JSPL/Spectrometer_History')
      .doc(params.id)
      .get();
  }
  deletePreventiveMaintenanceData(params) {
    return this.db
      .collection('/Asset_OEE/JSPL/Preventive_Maintenance')
      .doc(params.id)
      .delete();
  }
  /********************JSPL PHASE 2************************ */
  getKpisforExecutiveDashboard(params?: any) {
    return this.db
      .collection('/JSPL_Analytics/JSPL_Railmill/Railmill_KPI', (ref) =>
        ref
          .where('Date', '>=', params?.startDate)
          .where('Date', '<=', params?.endDate)
      )
      .get();
  }
  getTrackingData(params?: any) {
    return this.db
      .collection('/Asset_OEE/JSPL/Tracking', (ref) =>
        ref
          .where('rhf_exit_time', '>=', params.startDate)
          .where('rhf_exit_time', '<=', params.endDate)
      )
      .valueChanges();
  }
  getTrackingHistoryData(params?: any) {
    return this.db
      .collection('/Asset_OEE/JSPL/Tracking', (ref) =>
        ref
          .where('rhf_exit_time', '>=', params.startDate)
          .where('rhf_exit_time', '<=', params.endDate)
      )
      .get();
  }
  getCurrentShiftData(params?: any) {
    return this.db
      .collection(params?.collection, (ref) =>
        ref
          .where('timestamp', '>=', params?.startDate)
          .where('timestamp', '<=', params?.endDate)
      )
      .snapshotChanges();
  }
  getSchedulesData(params?: any) {
    if (params?.area) {
      return this.db
        .collection(
          `/Asset_OEE/JSPL/Schedules/${params.type}/${params.frequency}/${params.agency}/${params.alertType}`,
          (ref) => ref.where('area', '==', params?.area)
        )
        .get();
    } else {
      return this.db
        .collection(
          `/Asset_OEE/JSPL/Schedules/${params.type}/${params.frequency}/${params.agency}/${params.alertType}`
        )
        .get();
    }
  }
  scheduleUpdate(body?: any, params?: any, naParams?: any) {
    if (naParams['frequency'] == undefined) {
      return this.db
        .collection(
          `/Asset_OEE/JSPL/Schedules/${params.type}/${body.frequency}/${body.agency}/${body.alertType}`
        )
        .doc(params.id)
        .set(body, { merge: true });
    } else {
      return this.db
        .collection(
          `/Asset_OEE/JSPL/Schedules/${params.type}/${naParams.frequency}/${body.agency}/${body.alertType}`
        )
        .doc(params.id)
        .set(body, { merge: true });
    }
  }
  getKPIData(params?: any) {
    let docRef = this.db.collection(
      '/JSPL_Analytics/JSPL_Railmill/Railmill_KPI',
      (ref) =>
        ref
          .where('assetcode', '==', params.conditions[0].value)
          .where('type', '==', params.conditions[1].value)
          .orderBy('Date', 'desc')
          .limit(1)
    );
    return docRef.valueChanges();
  }

  getkpihistoryData(params?: any) {
    let docRef = this.db.collection(
      '/JSPL_Analytics/JSPL_Railmill/Railmill_KPI',
      (ref) =>
        ref
          .where('assetcode', '==', params.conditions[0].value)
          .where('Date', '>=', params.conditions[2].value)
          .where('Date', '<=', params.conditions[3].value)
    );
    return docRef.get();
  }
  getChargingSheetNO(params?: any) {
    return this.db.collection('/Charging_sheet').valueChanges();
  }

  // Phase 2 end

  // Spectrometer

  //Spectrometer Chemicals,Plants,Grades,Materials,Inspection Origin,Inspection Point
  addSpectrometerConfiguration(collection, params) {
    return this.db.collection(`/Asset_OEE/JSPL/${collection}`).add(params);
  }
  updateSpectrometerConfiguration(collection, id, data) {
    return this.db
      .collection(`/Asset_OEE/JSPL/${collection}`)
      .doc(id)
      .update(data);
  }
  getSpectrometerConfiguration(collection, params?) {
    return this.db.collection(`/Asset_OEE/JSPL/${collection}`).get();
  }
  deleteSpectrometerConfiguration(collection, id) {
    return this.db.collection(`/Asset_OEE/JSPL/${collection}`).doc(id).delete();
  }

  getGrades(params) {
    let docRef = this.db.collection(
      '/Asset_OEE/JSPL/Spectrometer_Grades',
      (ref) => ref.where('gradeName', '==', params.gradeName)
    );
    return docRef.get();
  }
  getChemicals(params) {
    let docRef = this.db.collection(
      '/Asset_OEE/JSPL/Spectrometer_Chemicals_List',
      (ref) => ref.where('chemicalName', '==', params.chemicalName)
    );
    return docRef.get();
  }

  // saveElementOrder(params) {
  //   return this.http.post(
  //     this.cloudFuncUrl + 'saveElementOrder',
  //     params,
  //     this.httpOptions
  //   );
  // }
  // Spectrometer Live Data
  getSpectrometerLive() {
    return this.db.collection('/Asset_OEE/JSPL/Spectrometer_Live').get();
  }

  // RHF Logbook
  getRhfLogBookLive(params) {
    return this.db
      .collection('/Asset_OEE/JSPL/rhf_logbook', (ref) =>
        ref
          .where('Date', '>=', params.startDate)
          .where('Date', '<=', params.endDate)
          .where('shift', '==', params.shift)
      )
      .snapshotChanges();
  }
  getRailMilKPIs(params?: any) {
    return this.db
      .collection('/JSPL_Analytics/JSPL_Railmill/Railmill_KPI', (ref) =>
        ref
          .where('Date', '>=', params?.startDate)
          .where('Date', '<=', params?.endDate)
          .where('assetcode', '==', params?.assetcode)
          .where('type', '==', params?.type)
          .where('Shift_Running', '==', params?.shift)
      )
      .valueChanges();
  }
  updateRhfBook(params?: any, body?: any) {
    return this.db
      .collection('/Asset_OEE/JSPL/rhf_logbook')
      .doc(params?.id)
      .update(body);
  }
  getHistoryGrade(params) {
    return this.db
      .collection('/Asset_OEE/JSPL/Spectrometer_History', (ref) =>
        ref.where('batchHeatNo', '==', params.heat)
      )
      .get();
  }
  sendMail(body: any) {
    return this.http.post(
      'https://asia-south1-etp-paint-shop.cloudfunctions.net/sendmail',
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
  getUsersMail() {
    return this.db.collection(`/Asset_OEE/JSPL/UserMail`).get();
  }
  getFireBaseData(params?: any) {
    return new Promise((resolve, reject) => {
      let collectionName = params.collection;
      let collectionPath = params.collectionPath;
      let id = params.id;
      let type = params.type;
      let conditions = params.conditions;
      if (params.conditions && typeof params.conditions == 'string') {
        conditions = JSON.parse(params.conditions);
      }
      let method = 'get';
      if (params.method) {
        method = params.method;
      }
      let limit: any;
      let orderByKey: any;
      let order: any;
      if (params.orderby) {
        orderByKey = params.orderby;
        order = params.order;
      }
      if (params.limit) {
        limit = parseInt(params.limit);
      }
      if (collectionName) {
        let docRef: any;
        if (params.collection && params.collectionPath == undefined) {
          docRef = this.db.collection(`${collectionName}`).ref;
        } else if (params.collection && params.collectionPath) {
          docRef = this.db.collection(
            `${collectionName}/${collectionPath}`
          ).ref;
        }
        if (conditions && conditions.length > 0) {
          conditions.forEach((element: any) => {
            docRef = docRef.where(element.key, element.symbol, element.value);
          });
        }
        if (orderByKey) {
          // if (params.source && params.source == 'throughput') {
          //   docRef = docRef.orderBy('weight_mt', order);
          // }
          docRef = docRef.orderBy(orderByKey, order);
        }
        if (limit) {
          docRef = docRef.limit(limit);
        }
        if (type == 'history') {
          docRef.get().then((element) => {
            if (element.size) {
              let promises: any = [];
              let resultData: any = [];
              element.forEach((doc) => {
                resultData = { ...doc.data(), ...{ id: doc.id } };
                promises.push(resultData);
                resolve({ data: promises, status: 'success' });
              });
            } else {
              resolve({ message: 'No Document Found', status: 'failure',data:[] });
            }
          });
        } else if (type == 'live') {
        }
      } else {
        resolve({ message: 'Missing collectionName', status: 'failure', data:[] });
      }
    });
  }

  updateThresholdSchedules(body?: any, params?: any) {
    if (params['type'] == 'communication_Alerts') {
      return this.db
        .collection(`/Asset_OEE/JSPL/Schedules/${params['type']}/Assets`)
        .doc(params['assetcode'])
        .update(body);
    } else {
      if (params['tags'] && params['asset']) {
        return this.db
          .collection(
            `/Asset_OEE/JSPL/Schedules/${params['type']}/${params['asset']}`
          )
          .doc(params['tags'])
          .update(body);
      } else {
        return this.db
          .collection(
            `/Asset_OEE/JSPL/Schedules/${params['type']}/${body['asset']}`
          )
          .doc(body['component'])
          .update(body);
      }
    }
  }
  getSpectrometerData(params) {
    let collection = '/Asset_OEE/JSPL/';
    if (params.collectionName) {
      collection += params.collectionName;
    } else {
      collection = params.collectionPath;
    }

    return this.db
      .collection('/Asset_OEE/JSPL/' + params.collectionName, (ref) =>
        ref
          .where('department', 'in', ['sms_3', 'sms_2'])
          .where(
            'timestamp',
            '>=',
            moment('01-12-2023', 'DD-MM-YYYY').startOf('day').toDate()
          )
          .where(
            'timestamp',
            '<=',
            moment('18-12-2023', 'DD-MM-YYYY').endOf('day').toDate()
          )
      )
      .get();
    //  let docRef =  this.db.collection(collection, query => {
    //     if(params.conditions){
    //       params.conditions.forEach(data =>{
    //         query.where(data.key, data.symbol, data.value)
    //       })
    //     }
    //     if(params.orderBy){
    //       query.orderBy(params.orderBy,"desc")
    //     }
    //     return query
    //   }
    //   )
    //   return docRef.get()
  }
  getJsplDoc() {
    return this.db.collection('/Asset_OEE').valueChanges();
  }
  getToken(param: any) {
    return this.http.post(
      'https://jspl-dserver-5rtvyi44aq-uc.a.run.app/token/',
      param,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }
  getRefreshToken(param: any) {
    return new Promise((resolve, reject) => {
      return this.http
        .post(
          'https://jspl-dserver-5rtvyi44aq-uc.a.run.app/token/refresh/',
          param,
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
          }
        )
        .subscribe(
          (result: any) => {
            localStorage.setItem('tokens', result);
            this.util.setToken(result);
            resolve(true);
          },
          (err) => {
            localStorage.removeItem('tokens');
            this.router.navigate(['login']);
          }
        );
    });
  }
  updateScheduledUsers(id: any, data: any) {
    return this.db.collection(`/Asset_OEE/JSPL/Tags`).doc(id).update(data);
  }

  //genAI
  addSearchContent(collection, params) {
    return this.db.collection(`/Asset_OEE/JSPL/${collection}`).add(params);
  }
  updateSearchContent(collection, id, data) {
    return this.db
      .collection(`/Asset_OEE/JSPL/${collection}`)
      .doc(id)
      .set(data, { merge: true });
  }
  getSearchContent(collection, params?) {
    return this.db.collection(`/Asset_OEE/JSPL/${collection}`).get();
  }
  deleteSearchContent(collection, id) {
    return this.db.collection(`/Asset_OEE/JSPL/${collection}`).doc(id).delete();
  }

  getGenAIResponse(params) {
    return this.http.post(
      'https://django-gupr34d6ba-uc.a.run.app/ask',
      params,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        }),
      }
    );
  }
  async flowiseQuery(data, api) {
    const response = await fetch(
      api['baseUrl'], // flowise
      // "https://django-gupr34d6ba-uc.a.run.app/ask", // django
      {
        method: api['method'],
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );
    const result = await response.json();
    return result;
  }
  getQuestions() {
    return this.db.collection('/Asset_OEE/JSPL/conversationAI/').valueChanges();
  }
  customQuery(params) {
    let apiDetails = params.apiDetails;
    let httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    let getQuery = _.cloneDeep(apiDetails.reqParams);
    if (params.datesRequired) {
      getQuery['startDate'] = moment()
        .subtract(apiDetails.reqParams['startDate'], 'days')
        .format('DD-MM-YYYY');
      getQuery['endDate'] = moment()
        .subtract(apiDetails.reqParams['endDate'], 'days')
        .format('DD-MM-YYYY');
    }
    if (getQuery['report_type'] == 'report') {
      getQuery['to'] = [localStorage.getItem('email')];
      getQuery['to'] = JSON.stringify(getQuery['to']);
    }
    let options = {
      headers: httpHeaders,
      params: getQuery,
    };
    if (apiDetails.method == 'get') {
      return this.http.get(apiDetails.baseUrl, options);
    } else if (params.apiDetails.method == 'post') {
      return this.http.post(apiDetails.baseUrl, getQuery, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      });
    } else {
      return this.http.get(apiDetails.baseUrl, options);
    }
  }
  getChecklist(param: any) {
    return new Promise(async (resolve, reject) => {
      let queryParam = {
        collection: '/Asset_OEE/JSPL/Checklist',
        type: 'history',
        conditions: param['conditions'],
      };
      this.getFireBaseData(queryParam)
        .then(async (response: any) => {
          if (param['checklistType'] == 'today') {
            await this.getMaintenanceData(response['data'], param).then(
              (maintenanceResponse: any) => {
                resolve(maintenanceResponse);
              }
            );
          } else if (param['checklistType'] == 'yesterday') {
            await this.getYesterdayMaintenanceData(
              response['data'],
              param
            ).then((maintenanceResponse: any) => {
              resolve(maintenanceResponse);
            });
          }
        })
        .catch((err) => {
          console.log(err);
          resolve({ status: 'failure' });
        });
    });
  }
  getMaintenanceData(checklists: any, inputData: any) {
    return new Promise((resolve, reject) => {
      let resutData: any = [];
      let resultIndex = 0;
      let days = {
        '0': 'Sunday',
        '1': 'Monday',
        '2': 'Tuesday',
        '3': 'Wednesday',
        '4': 'Thursday',
        '5': 'Friday',
        '6': 'Saturday',
      };
      let shiftData = [
        {
          label: 'Shift A',
          value: '06-14',
        },
        {
          label: 'Shift B',
          value: '14-22',
        },
        {
          label: 'Shift C',
          value: '22-06',
        },
      ];
      if (checklists.length) {
        async.eachSeries(checklists, (checklist: any, resultcb) => {
          let checkListDocId = checklist.checklistId;
          let selectedDate: any = {};
          console.log(checklist.frequency);
          let currentTime = moment().unix();
          let startTime = moment(currentTime).startOf('day').unix();
          let endTime = moment(startTime).add(6, 'hours').unix();
          if (currentTime >= startTime && currentTime <= endTime) {
            selectedDate['startDate'] = moment()
              .subtract(1, 'day')
              .startOf('day')
              .add(6, 'hours')
              .toDate();
            selectedDate['endDate'] = moment()
              .startOf('day')
              .add(6, 'hours')
              .toDate();
          } else {
            selectedDate.startDate = moment()
              .startOf('day')
              .add(6, 'hours')
              .toDate();
            selectedDate.endDate = moment()
              .add(1, 'day')
              .startOf('day')
              .add(6, 'hours')
              .toDate();
          }
          if (checklist.frequency && checklist.frequency == 'Weekly') {
            let selectedKey = '';
            for (let key in days) {
              if (days[key] == checklist.freqencyDay) {
                selectedKey = key;
                break;
              }
            }
            let diff = moment().day() - parseInt(selectedKey);
            let signInt = Math.sign(diff);
            if (signInt == 1 || signInt == 0) {
              selectedDate.startDate = moment()
                .subtract(diff, 'days')
                .startOf('day')
                .utc()
                .toDate();
            } else {
              selectedDate.startDate = moment()
                .add(diff, 'days')
                .startOf('day')
                .utc()
                .toDate();
            }
            selectedDate.endDate = moment(selectedDate.startDate)
              .add(6, 'days')
              .endOf('day')
              .utc()
              .toDate();
          } else if (checklist.frequency && checklist.frequency == 'Monthly') {
            //&& checklist.freqencyDate == moment().format("D")
            let currentDate = new Date();
            // let prepareStartDate = currentDate.getFullYear()+"-"+(currentDate.getMonth()+1)+"-"+checklist.freqencyDate+" 00:00:00";
            // let prepareEndDate = currentDate.getFullYear()+"-"+(currentDate.getMonth()+2)+"-"+(checklist.freqencyDate-1)+" 00:00:00";
            // selectedDate.startDate = moment(new Date(prepareStartDate)).startOf('day').utc().toDate();
            // selectedDate.endDate = moment(new Date(prepareEndDate)).endOf('day').utc().toDate();
            selectedDate.startDate = moment([
              currentDate.getFullYear(),
              currentDate.getMonth(),
            ])
              .startOf('day')
              .utc()
              .toDate();
            selectedDate.endDate = moment(selectedDate.startDate)
              .endOf('month')
              .endOf('day')
              .utc()
              .toDate();
          } else if (
            checklist.frequency &&
            checklist.frequency == 'Shiftwise'
          ) {
            let selectedShiftData: any = {};
            let shiftNo = 0;
            // selectedDate.startDate = moment().startOf('day')
            // selectedDate.startDate.set({ h: 6, m: 0 }).toDate();
            // selectedDate.endDate = moment();
            let filterShift = shiftData.filter((shiftData) => {
              return shiftData.label == checklist.shift;
            });
            if (filterShift.length > 0) {
              selectedShiftData = filterShift[0];
              shiftNo = shiftData.indexOf(selectedShiftData) + 1;
              let shiftHour = shiftNo * 8;
              selectedDate.endDate = moment(selectedDate.startDate).add(
                shiftHour,
                'hour'
              );
              if (shiftNo > 1) {
                let startShiftHour = 8 * (shiftNo - 1);
                selectedDate.startDate = moment(selectedDate.startDate).add(
                  startShiftHour,
                  'hour'
                );
              }
            }
            selectedDate.startDate = moment(selectedDate.startDate)
              .utc()
              .toDate();
            selectedDate.endDate = moment(selectedDate.endDate).utc().toDate();
          }
          if (
            checkListDocId &&
            selectedDate.startDate &&
            selectedDate.endDate
          ) {
            let filter = [
              {
                key: 'checklistId',
                symbol: '==',
                value: checkListDocId,
              },
              {
                key: 'maintenanceDate',
                symbol: '>=',
                value: moment(selectedDate.startDate).toDate(),
              },
              {
                key: 'maintenanceDate',
                symbol: '<=',
                value: moment(selectedDate.endDate).toDate(),
              },
            ];
            let params = {
              collection: '/Asset_OEE/JSPL/Maintenance',
              conditions: filter,
              type: 'history',
            };
            this.getFireBaseData(params).then((result: any) => {
              if (result.status == 'success') {
                checklist.maintenanceData = result.data;
                resutData.push(checklist);
                if (checklists.length - 1 == resultIndex) {
                  resolve({ data: checklists, status: 'success' });
                } else {
                  resultIndex++;
                  resultcb();
                }
              } else {
                resutData.push(checklist);
                // resolve({ data: checklists, status: "success" })
                if (checklists.length - 1 == resultIndex) {
                  resolve({ data: checklists, status: 'success' });
                } else {
                  resultIndex++;
                  resultcb();
                }
              }
            });
          } else {
            if (checklists.length - 1 == resultIndex) {
              resolve({ data: checklists, status: 'success' });
            } else {
              resultIndex++;
              resultcb();
            }
          }
        });
      } else {
        resolve({ data: checklists, status: 'success' });
      }
    });
  }
  getYesterdayMaintenanceData(checklists, res) {
    return new Promise((resolve, reject) => {
      let resutData: any = [];
      let resultIndex = 0;
      let days = {
        '0': 'Sunday',
        '1': 'Monday',
        '2': 'Tuesday',
        '3': 'Wednesday',
        '4': 'Thursday',
        '5': 'Friday',
        '6': 'Saturday',
      };
      let shiftData = [
        {
          label: 'Shift A',
          value: '06-14',
        },
        {
          label: 'Shift B',
          value: '14-22',
        },
        {
          label: 'Shift C',
          value: '22-06',
        },
      ];

      if (checklists.length) {
        async.eachSeries(checklists, (checklist: any, resultcb) => {
          let checkListDocId = checklist.checklistId;
          let selectedDate: any = {};
          let currentTime = moment();
          let startTime = moment().startOf('day').utc().toDate();
          let endTime = moment(startTime).add(6, 'hours').utc().toDate();
          if (currentTime.isBetween(startTime, endTime)) {
            console.log('Entered ISBetween', moment().utc().toDate());
            selectedDate.startDate = moment()
              .subtract(2, 'day')
              .startOf('day')
              .add(6, 'hours')
              .utc()
              .toDate();
            selectedDate.endDate = moment()
              .subtract(1, 'day')
              .startOf('day')
              .add(6, 'hours')
              .utc()
              .toDate();
          } else {
            selectedDate.startDate = moment()
              .subtract(1, 'day')
              .startOf('day')
              .add(6, 'hours')
              .utc()
              .toDate();
            selectedDate.endDate = moment()
              .startOf('day')
              .add(6, 'hours')
              .utc()
              .toDate();
          }
          if (
            checkListDocId &&
            selectedDate.startDate &&
            selectedDate.endDate
          ) {
            let filter = [
              {
                key: 'checklistId',
                symbol: '==',
                value: checkListDocId,
              },
              {
                key: 'maintenanceDate',
                symbol: '>=',
                value: selectedDate.startDate,
              },
              {
                key: 'maintenanceDate',
                symbol: '<=',
                value: selectedDate.endDate,
              },
            ];
            let queryparam = {
              collection: '/Asset_OEE/JSPL/Maintenance',
              conditions: filter,
              type: 'history',
            };
            this.getFireBaseData(queryparam).then((result: any) => {
              if (result.status == 'success') {
                checklist.maintenanceData = result.data;
                resutData.push(checklist);
                if (checklists.length - 1 == resultIndex) {
                  resolve({ data: checklists, status: 'success' });
                } else {
                  resultIndex++;
                  resultcb();
                }
              } else {
                resutData.push(checklist);
                // resolve({ data: checklists, status: "success" })
                if (checklists.length - 1 == resultIndex) {
                  resolve({ data: checklists, status: 'success' });
                } else {
                  resultIndex++;
                  resultcb();
                }
              }
            });
          } else {
            if (checklists.length - 1 == resultIndex) {
              resolve({ data: checklists, status: 'success' });
            } else {
              resultIndex++;
              resultcb();
            }
          }
        });
      } else {
        resolve({ data: checklists, status: 'success' });
      }
    });
  }
  generatePmReport(param: any) {
    return this.http.post(
      'https://asia-south1-jspl-355706.cloudfunctions.net/PredictAI_JSPL_Maintenance/getPmReports',
      param,
      this.httpOptions
    );
  }
  regenerate_report(param: any) {
    if(param.type=='delay'){
       let params = new HttpParams()
         .set('insert', 'false')
         .set('value', 'delay')
         .set('date',param.date)
    return this.http.get(
      'https://asia-south1-jspl-355706.cloudfunctions.net/JSPL_Reports',
      { headers:this.httpOptions, params: params }
    );
  }
  else{
  return this.http.post(
      'https://asia-south1-jspl-355706.cloudfunctions.net/PredictAI_JSPL_Maintenance/regenerate_report',
      param,
      this.httpOptions
    );
  }
  }
  get_genAI_response(params: any) {
    return this.http.post(this.gen_ai_api, params, this.httpOptions);
  }
  update_schedules(param){
    return this.db.collection(param.collection).doc(param.id).set(param.dataToUpdate,{merge:true})
  }
  modify_firestore_doc(param){
    return this.db.collection(param.collection).doc(param.id).set(param.dataToUpdate,{merge:true})
  }
  // get chargingsheet wise count
  getTrackingTagsData(){
    return this.db.collection('/Asset_OEE/JSPL/Tracking_Counter').get()
  }
}
