import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { SubjectService } from 'src/app/services/subject.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as filterOption from './../../../../assets/json/filterOption.json';
import * as kpiData from './../../../../assets/json/kpiDAta.json';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util.service';
import moment, { Moment } from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FirestoreService } from 'src/app/services/firestore.service';
import * as _ from 'lodash';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../../../environments/environment';
import { Subscription } from 'rxjs';
import { Data } from './data';
import { concatAll, timestamp } from 'rxjs/operators';
import { compact } from 'lodash';
import { KpiComponent } from './kpi/kpi.component';
import { SubSink } from 'subsink';
import Swal from 'sweetalert2';
import { timeStamp } from 'console';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-assetdetails',
  templateUrl: './assetdetails.component.html',
  styleUrls: ['./assetdetails.component.scss'],
})
export class AssetdetailsComponent implements OnInit, OnDestroy {
  subsink = new SubSink();
  kpiData: any[];
  liveKpiData: any = [];
  productionData: any = [];
  dev = environment.dev;
  lastUpdatedLiveTag: any;
  filterList: any;
  selectedFilter: string = 'Live';
  selectedFilter_graph_data: string = 'Live';
  aggregateType: string = 'Live';
  populate_live_data: boolean = true;
  data: any = {};
  progressBarData: any = [];
  alertData: any = [];
  chartData: any = [];
  assetData: any = [];
  tagData: any = [];
  widgetData: any = [];
  widgetListData: any = [];
  addWidgetObj: any = {
    options: {
      gridColumn: 1,
      tagMapping: [],
    },
  };
  selectedAssetWidgetTags: any = [];
  selectedWidgetTags: any = {};
  selectedAssetData: any = {};
  selectedPopupChart: any = {};
  role: string = '';
  widgetButton: string = 'ADD';
  subscription_selectedAssetData: any;
  subscription_assetData: any;
  subscription_alertData: any;
  subscription_tagData: any;
  subscription_widgetData: any;
  sidebarSelected: any = {
    title: 'Overview',
  };
  selectedTabs: any = {};

  tabs: any = [];
  selectedIndex: number = 0;
  tabsWidth: number = 140;
  /******************************************* */
  data_frequency: string = '';
  startDate: any = moment().subtract(7, 'day').startOf('day').toDate();
  endDate: any = moment().toDate();
  // startDate = moment().subtract(15, "minutes").format("MM/DD/YYYY HH:mm:ss");
  // endDate = moment().format("MM/DD/YYYY HH:mm:ss");
  // startDate = moment().startOf('day').format("MM/DD/YYYY HH:mm:ss");
  // endDate = moment().format("MM/DD/YYYY HH:mm:ss");
  historyResponse: any = [];
  initialDataSubscription: any;
  indicatorsubscription: any;
  historySubcription: any;
  xaxis: any = [];
  datapoints: any = {};
  HistoryChartObj: any = {};
  chartname: string = '';
  overallHealth: any;
  history_chart: any;
  csvoptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: '',
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  };
  uniqueCode: string = '';
  widthList: any = {
    data: [
      { width: '16 %', value: 'col-2' },
      { width: '25 %', value: 'col-3' },
      { width: '33 %', value: 'col-4' },
      { width: '42 %', value: 'col-5' },
      { width: '50 %', value: 'col-6' },
      { width: '58 %', value: 'col-7' },
      { width: '66 %', value: 'col-8' },
      { width: '75 %', value: 'col-9' },
      { width: '83 %', value: 'col-10' },
      { width: '92 %', value: 'col-11' },
      { width: '100 %', value: 'col-12' },
    ],
    selected: {},
  };
  widgetCategory: any = {
    data: [
      { id: 'CHART', name: 'Chart' },
      // { id: 'HEADING', name: 'Heading' },
      { id: 'SINGLE_CARD', name: 'Single Card' },
      // { id: 'GRID_CARD', name: 'Grid Card' },
      // { id: 'LIST_CARD', name: 'List Card' },
      // { id: "POSITION", name: "Position Widget" },
      // { id: 'SHIFTWISE', name: 'Shiftwise Widget' },
      // { id: "TRACKING", name: "Tracking Widget" },
      // { id: "BURNER", name: "Burner Status" },
      // { id: 'DATATABLE', name: 'Data Table' },
      // { id: 'DIAGRAM', name: 'Diagram Widget' },
    ],
    selected: {},
  };
  positionCategory: any = {
    data: ['Charging', 'Walking Beam', 'Discharging'],
  };
  diagramCategory: any = {
    data: [
      'Charging',
      'Walking Beam',
      'Discharging',
      'Tracking',
      'Burner Status',
    ],
  };
  shiftwiseCategory: any = {
    data: ['Charge', 'Discharge'],
  };
  listDisplayTyes: any = {
    data: ['Horizontal', 'Vertical', 'Skid Monitoring'],
  };
  userSpecific:any = {
    data:["All Users","Current User"],
    selected:"All Users"
  }
  chartType:any = {
    data:["Spline", "Bar","Column"],
    selected:"Spline"
  }
  acknowledgeList = ['Yes', 'No'];
  selectedWidth: any = { key: 'col-md-7', value: '58%' };
  liveTagData: any = [];
  widgetForm: FormGroup;
  assetImageSrc: string = '';
  // @ViewChild('daterange', { static: false })
  // daterange!: ElementRef;

  dateoptions: any = {
    alwaysShowCalendars: true,
  };
  maxDate;
  localdata = {
    displayFormat: 'MM/DD/YYYY, hh:mm a',
  };
  /******************************************* */
  liveDataSubscription: Subscription | undefined;
  sidebarDropdown: any = [];
  sidebarDropdownSelected: any = {};
  daterangeValue: any = document.getElementById('daterange');
  InitialDates: any;
  liveData: any;
  currentShift: any;
  lastUpdated: any;
  previousShift = { B: 'A', C: 'B', A: 'C' };
  prev_shift: any;
  details_kpi: any;
  aggregateFrame: string;
  error: string;
  alertFilterCount: number;
  alertFilter: any;
  alertList: string[];
  rhf2Data: any;
  p: number = 1;
  KPIList: any = [
    { label: 'Pass Schedule', field: 'Pass Schedule' },
    { label: 'Ready Checklist', field: 'Process InterLocks' },
    { label: 'Lubricant', field: 'Drive Parameters' },
  ];
  selectedKpi = this.KPIList[0];
  LiveTimestamp: any;
  selectedWidgetData: any;
  prod_timestamp = moment().subtract(1, 'day').format('DD MMM YYYY');
  loggedinUser:any;
  constructor(
    private http: HttpService,
    private subject: SubjectService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
    private util: UtilService,
    private fs: FirestoreService,
    public formBuilder: FormBuilder,
    private auth:AuthService
  ) {
    this.loggedinUser  = this.auth.getUserObject();
    this.activeRoute.params.subscribe((param) => {
      this.uniqueCode = param.code;
    });
    this.role = localStorage.getItem('role') || '';
    this.subscription_assetData = this.subject
      .getAssetData()
      .subscribe((data) => {
        if (!data) {
          let assetParams = {
            category: 'ASSETS',
            conditions: [
              { key: 'is_maintenance_asset', symbol: '==', value: false },
            ],
          };
          this.fs.getData(assetParams).subscribe((response: any) => {
            if (response.status == 'success') {
              this.assetData = response.data;
              // this.assetData = this.assetData.filter(a =>{
              //   return a.assetcode != 'OPCUAMILL_AREA' && a.assetcode != 'OPCUAUTILITY'
              // })
              this.assetData.forEach((element) => {
                element.customName = element.customName
                  ? element.customName
                  : element.asset;
                // element.code = (element.assetcode + element.asset).toUpperCase().replace(/ /g, "_");
                element.mode =
                  element.alert_count > 0 ? 'Not Healthy' : 'Healthy';
              });
              if (this.assetData.length > 0) {
                this.loadAssetDetail(this.assetData);
              }
            }
          });
        } else {
          this.assetData = data;
          if (this.assetData.length > 0) {
            this.loadAssetDetail(this.assetData);
          }
        }
        this.subscription_selectedAssetData = this.subject
          .getSelectedAssetData()
          .subscribe((data) => {
            if (data) {
              this.selectedAssetData = data;
              this.selectedAssetData.asset =
                this.selectedAssetData.asset == 'Tandem'
                  ? 'tandem'
                  : this.selectedAssetData.asset;
            }
          });
      });
    this.widgetForm = this.formBuilder.group({
      title: [null, []],
      tag_info: this.formBuilder.array([]),
      width: [null, []],
      //formulas: this.formBuilder.array([this.addFormula()]),
      //tagMapping: this.formBuilder.array([this.formBuilder.group({label:"",tag:""})]),
      formulas: this.formBuilder.group({
        PRE: this.formBuilder.array([this.addFormula()]),
      }),
    });
  }
  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
  // get tagMapping() {
  //   return this.widgetForm.get('tagMapping') as FormArray;
  // }
  navigateToAlerts() {
    this.router.navigate(['/page/alerts']);
    this.subject.setselectedmenu({ index: 1 });
  }
  acknowledge(currentVal: string, value: any) {
    value.acknowledge = currentVal;
    value.state = currentVal;
    let updateObj = {
      id: value.id,
      acknowledge: value.state,
      update_key: 'acknowledge',
    };
    let state = value.state == 'Yes' ? 'closed' : 'new';
    this.fs
      .fireStoreDocUpdate('ALERTS', value.id, {
        acknowledge: value.acknowledge,
        state: state,
      })
      .subscribe(
        (response: any) => {
          if (response.status == 'success') {
            // let filterData = this.getFilter();
            this.loadAlertData().then((res) => {});
            this.util.showSnakbarMessage(
              'success',
              'Acknowledge Value Update Successfully.',
              'end',
              'top'
            );
          }
        },
        (err) => {
          this.util.showSnakbarMessage(
            'error',
            err.error.message,
            'end',
            'top'
          );
        }
      );
  }
  ngOnInit(): void {
    this.InitialDates = { startDate: this.startDate, endDate: this.endDate };
    this.daterangeValue = document.getElementById('daterange');
    this.data_frequency = `${moment(this.startDate).format(
      'MM/DD/YYYY, hh:mm a'
    )} - ${moment(this.endDate).format('MM/DD/YYYY, hh:mm a')}`;
    if (this.daterangeValue) {
      this.daterangeValue.value = this.data_frequency;
    }
    this.filterList = (filterOption as any)['default'];
    this.progressBarData = [
      {
        name: 'OEE',
        value: '86',
        unit: '%',
      },
      {
        name: 'Performance',
        value: '72',
        unit: '%',
      },
      {
        name: 'Quality',
        value: '52',
        unit: '%',
      },
    ];
  }

  selectedDates(e) {
    // console.log(e);
  }
  customchangeDuration(event: any) {
    if (event.startDate) {
      this.details_kpi = {
        asset: this.selectedAssetData,
        date: this.selectedFilter,
        customDates: event,
        KPI: this.selectedKpi,
      };
      let startDate = moment(event.startDate.$d);
      let endDate = moment(event.endDate.$d);
      let dateDiff = endDate.diff(startDate, 'minutes');
      if (dateDiff > 0 && dateDiff <= 30) {
        this.aggregateFrame = 'HISTORY';
      } else if (dateDiff > 30 && dateDiff <= 1440) {
        this.aggregateFrame = '10_min';
      } else if (dateDiff > 1440 && dateDiff <= 10080) {
        this.aggregateFrame = '60_min';
      } else {
        this.aggregateFrame = '1_day';
      }
      this.startDate = moment(event.startDate.$d).toDate();
      this.endDate = moment(event.endDate.$d).toDate();
      this.data_frequency = `${moment(this.startDate).format(
        'MM/DD/YYYY, hh:mm a'
      )} - ${moment(this.endDate).format('MM/DD/YYYY, hh:mm a')}`;
      this.aggregateType = 'Month';
      if (this.daterangeValue) {
        this.daterangeValue.value = this.data_frequency;
      }
      if (this.selectedWidgetData) {
        this.selectedWidgetData['loading'] = true;
        let filterData = this.widgetData.filter((item) => {
          return item['id'] == this.selectedWidgetData['id'];
        });
        filterData.forEach((element: any) => {
          element.live = event != 'Live' ? false : true;
          element.chart_data = [];
          for (let t = 0; t < element.tag_info.length; t++) {
            //if (!this.selectedWidgetTags[element.tag_info[t]]) {
            this.selectedWidgetTags[element.tag_info[t]] = [];
            //}
          }
        });
        //console.log(event);
        this.loadAlertData().then((res) => {
          this.getChartData(this.aggregateFrame, true, this.selectedWidgetData);
        });
      } else {
        this.widgetData.forEach((element: any) => {
          element.live = event != 'Live' ? false : true;
          element.chart_data = [];
          for (let t = 0; t < element.tag_info.length; t++) {
            //if (!this.selectedWidgetTags[element.tag_info[t]]) {
            this.selectedWidgetTags[element.tag_info[t]] = [];
            //}
          }
        });
        this.loadAlertData().then((res) => {
          this.getChartData(this.aggregateFrame);
        });
      }
    }
  }

  addFormula() {
    return this.formBuilder.group({
      axisName: [''],
      unit: [''],
      formula: [''],
    });
  }
  //sidebarMenuClicked(event: any, data: any, type: string) {
  sidebarMenuClicked(event: any) {
    //event.stopImmediatePropagation();
    // event.selectedMenuType
    this.KPIList = [];
    // console.log(this.selectedAssetData)
    if (
      this.selectedAssetData.asset == 'BD' ||
      this.selectedAssetData.asset == 'BD2'
    ) {
      this.KPIList = [
        { label: 'Pass Schedule', field: 'Pass Schedule' },
        { label: 'Ready Checklist', field: 'Process InterLocks' },
        { label: 'Motor Parameters', field: 'Drive Parameters' },
      ];
      this.selectedKpi = this.KPIList[0];
    } else if (this.selectedAssetData.asset.toLowerCase() == 'tandem') {
      this.KPIList = [
        { label: 'Main Motor Parameters', field: 'Main Motor Parameters' },
        { label: 'Pass Schedule', field: 'Pass Schedule' },
        { label: 'Roll Set Configuration', field: 'Roll_Set_Configuration' },
        { label: 'Preconditions/Interlocks', field: 'Preconditions/Interlock' },
        { label: 'HP-LP Pumps', field: 'HP-LP Pumps' },
        { label: 'Hydraulics', field: 'Hydraulics' },
      ];
      this.selectedKpi = this.KPIList[0];
    } else if (this.selectedAssetData.asset == 'HOT SAW') {
      this.KPIList = [
        { label: 'HP-LP Pumps', field: 'HP-LP Pumps' },
        { label: 'Fixed Saw', field: 'Fixed Saw' },
        { label: 'Movable Saw', field: 'Movable Saw' },
      ];
      this.selectedKpi = this.KPIList[0];
    } else if (this.selectedAssetData.asset == 'RHF2') {
      this.KPIList = [{ label: 'Handling', field: 'Handling' }];
      this.selectedKpi = this.KPIList[0];
    }
    this.filterList = (filterOption as any)['default'];
    this.selectedFilter = 'Live';
    this.selectedFilter_graph_data = 'Live';
    this.sidebarSelected = event.selectedMenuData;
    if (this.sidebarSelected.title) {
      if (this.sidebarSelected.title == 'Trends Monitor') {
        this.util.setLoaderValue(true);
        this.aggregateFrame = 'HISTORY';
        this.populate_live_data = true;
        let updatedData = Math.max.apply(
          Math,
          this.liveData.map((o) => {
            return o.timestamp._seconds;
          })
        );
        let lastTimestamp = moment(updatedData * 1000);
        this.endDate = lastTimestamp.toDate();
        this.startDate = lastTimestamp.subtract(30, 'minutes').toDate();
      } else if (this.sidebarSelected.title == 'KPI Monitor') {
        this.filterList = this.filterList.filter((d) => {
          return d != 'Live';
        });
        this.selectedFilter = 'Today';
        this.details_kpi = {
          asset: this.selectedAssetData,
          date: this.selectedFilter,
          KPI: this.selectedKpi,
        };
      }
      this.details_kpi = {
        asset: this.selectedAssetData,
        date: this.selectedFilter,
        KPI: this.selectedKpi,
      };
    }
    this.selectedTabs = undefined;
    if (this.sidebarSelected.tabs && this.sidebarSelected.tabs.length > 0) {
      this.details_kpi = {
        asset: this.selectedAssetData,
        date: this.selectedFilter,
        KPI: this.selectedKpi,
      };
      this.navigateToTab(this.sidebarSelected.tabs[0], 0);
    } else {
      this.loadWidgetData();
    }
  }
  navigateToTab(tab: any, index: number) {
    this.selectedTabs = tab;
    this.selectedIndex = index;
    this.loadWidgetData();
  }
  loadAssetDetail(assetArr: any) {
    let filterAsset = assetArr.filter((data: any) => {
      return data.id == this.uniqueCode;
    });
    this.selectedAssetData = filterAsset[0];
    this.subject.setSelectedAssetData(this.selectedAssetData);
    this.selectedAssetData.asset =
      this.selectedAssetData.asset == 'Tandem'
        ? 'tandem'
        : this.selectedAssetData.asset;
    this.getRunningHrs();
    if (
      this.selectedAssetData.sidebarConfig &&
      this.selectedAssetData.sidebarConfig.length > 0
    ) {
      this.selectedAssetData.sidebarConfig.forEach((element) => {
        if (element.title != 'Overview' && element.title != 'KPI Monitor') {
          element.label = element.title;
          this.sidebarDropdown.push(element);
          if (element.children) {
            element.children.forEach((submenu) => {
              submenu.label = submenu.parent + ' - ' + submenu.title;
              this.sidebarDropdown.push(submenu);
            });
          }
          if (element.tabs) {
            element.tabs.forEach((tab: any) => {
              tab.label = tab.parent + ' - ' + tab.title;
              this.sidebarDropdown.push(tab);
            });
          }
        }
      });
    }
    this.sidebarDropdownSelected = this.sidebarDropdown[0];
    this.loadLiveTags();
    this.loadImage();
    this.setAssetCustomName();
    this.loadWidgetListData();
    this.loadAlertData()
      .then((res) => {
        if (res) {
          this.loadAssetTags();
          this.loadWidgetData();
          // this.getChartData('HistoryData');
          if (!environment.cloud) {
            this.socketconnection();
          }
        }
      })
      .catch(() => {
        this.loadAssetTags();
        this.loadWidgetData();
        this.getChartData('HistoryData');
      });
  }
  sidebarDropdownChange(data: any) {
    this.sidebarDropdownSelected = data;
    this.loadWidgetListData();
  }

  loadImage() {
    if (this.selectedAssetData.assetImage) {
      this.assetImageSrc = this.selectedAssetData.assetImage;
    } else {
      this.assetImageSrc = './assets/images/assetimage/assetdefaultimage.jpeg';
    }
  }
  setAssetCustomName() {
    this.selectedAssetData.customName = this.selectedAssetData.customName
      ? this.selectedAssetData.customName
      : this.selectedAssetData.asset;
  }

  showIcon(icon: string, chart: any) {
    if (this.selectedFilter == 'Live') {
      return !chart.live;
    } else {
      return true;
    }
  }
  showPopup(chartData: any) {}

  duractionChange(event: any) {
    // console.log(event)
    this.details_kpi = {
      asset: this.selectedAssetData,
      date: event,
      KPI: this.selectedKpi,
    };
    this.populate_live_data = false;
    this.selectedFilter = event;
    if (event == 'Live') {
      this.populate_live_data = true;
      this.aggregateFrame = 'HISTORY';
      let updatedData = Math.max.apply(
        Math,
        this.liveData.map((o) => {
          return o.timestamp._seconds;
        })
      );
      let lastTimestamp = moment(updatedData * 1000);
      this.endDate = lastTimestamp.toDate();
      this.startDate = lastTimestamp.subtract(30, 'minutes').toDate();
    } else if (event == 'Last Hour') {
      this.aggregateFrame = 'HISTORY';
      this.startDate = moment().subtract(1, 'hours').toDate();
      this.endDate = moment().toDate();
    } else if (event == 'Today') {
      this.aggregateFrame = '10_min';
      this.startDate = moment().startOf('day').toDate();
      this.endDate = moment().toDate();
    } else if (event == 'Yesterday') {
      this.aggregateFrame = '10_min';
      this.startDate = moment().subtract(1, 'day').startOf('day').toDate();
      this.endDate = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (event == 'Week') {
      this.aggregateFrame = '60_min';
      this.startDate = moment().subtract(7, 'days').startOf('day').toDate();
      this.endDate = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (event == 'Month') {
      this.aggregateFrame = '1_day';
      this.startDate = moment().subtract(30, 'days').startOf('day').toDate();
      // this.endDate = moment().toDate()
      this.endDate = moment()
        .subtract(1, 'day')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss');
    } else if (event == 'Custom') {
      this.aggregateFrame = '60_min';
      this.startDate = moment(this.startDate).toDate();
      this.endDate = moment(this.endDate).toDate();
    }
    // if(event != 'Custom'){
    this.aggregateType = event;
    this.data_frequency = `${moment(this.startDate).format(
      'MM/DD/YYYY, hh:mm a'
    )} - ${moment(this.endDate).format('MM/DD/YYYY, hh:mm a')}`;
    if (this.daterangeValue) {
      // console.log(this.daterangeValue)
      this.daterangeValue.value = this.data_frequency;
    }
    this.widgetData.forEach((element: any) => {
      element.live = event != 'Live' ? false : true;
      element.chart_data = [];
      for (let t = 0; t < element.tag_info.length; t++) {
        //if (!this.selectedWidgetTags[element.tag_info[t]]) {
        this.selectedWidgetTags[element.tag_info[t]] = [];
        //}
      }
    });

    //console.log(event);
    this.loadAlertData().then((res) => {
      this.getChartData(this.aggregateFrame);
    });
    // }
  }
  graphDurationChnage(event: any, widgetdata) {
    // this.selectedFilter = event;
    this.selectedWidgetData = widgetdata;
    widgetdata['loading'] = true;
    widgetdata['filter'] = event;
    if (event == 'Live') {
      this.populate_live_data = true;
      this.aggregateFrame = 'HISTORY';
      let updatedData = Math.max.apply(
        Math,
        this.liveData.map((o) => {
          return o.timestamp._seconds;
        })
      );
      let lastTimestamp = moment(updatedData * 1000);
      this.endDate = lastTimestamp.toDate();
      this.startDate = lastTimestamp.subtract(30, 'minutes').toDate();
    } else if (event == 'Last Hour') {
      this.aggregateFrame = 'HISTORY';
      this.startDate = moment().subtract(1, 'hours').toDate();
      this.endDate = moment().toDate();
    } else if (event == 'Today') {
      this.aggregateFrame = '10_min';
      this.startDate = moment().startOf('day').toDate();
      this.endDate = moment().toDate();
    } else if (event == 'Yesterday') {
      this.aggregateFrame = '10_min';
      this.startDate = moment().subtract(1, 'day').startOf('day').toDate();
      this.endDate = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (event == 'Week') {
      this.aggregateFrame = '60_min';
      this.startDate = moment().subtract(7, 'days').startOf('day').toDate();
      this.endDate = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (event == 'Month') {
      this.aggregateFrame = '1_day';
      this.startDate = moment().subtract(30, 'days').startOf('day').toDate();
      // this.endDate = moment().toDate()
      this.endDate = moment()
        .subtract(1, 'day')
        .endOf('day')
        .format('MM/DD/YYYY HH:mm:ss');
    } else if (event == 'Custom') {
      this.aggregateFrame = '60_min';
      this.startDate = moment().subtract(7, 'day').startOf('day').toDate();
      this.endDate = moment().toDate();
    }
    // if(event != 'Custom'){
    this.aggregateType = event;
    this.data_frequency = `${moment(this.startDate).format(
      'MM/DD/YYYY, hh:mm a'
    )} - ${moment(this.endDate).format('MM/DD/YYYY, hh:mm a')}`;
    if (this.daterangeValue) {
      // console.log(this.daterangeValue)
      this.daterangeValue.value = this.data_frequency;
    }
    let filterData = this.widgetData.filter((item) => {
      return item['id'] == widgetdata['id'];
    });
    filterData.forEach((element: any) => {
      element.live = event != 'Live' ? false : true;
      element.chart_data = [];
      for (let t = 0; t < element.tag_info.length; t++) {
        //if (!this.selectedWidgetTags[element.tag_info[t]]) {
        this.selectedWidgetTags[element.tag_info[t]] = [];
        //}
      }
    });
    //console.log(event);
    this.loadAlertData().then((res) => {
      this.getChartData(this.aggregateFrame, true, widgetdata);
    });
    // }
  }
  changeAsset(event: any) {
    this.details_kpi = {
      asset: event,
      date: this.selectedFilter,
      KPI: this.selectedKpi,
    };
    this.selectedAssetData = event;
    // console.log(event)
    (this.sidebarSelected.title = 'Overview'),
      this.util.setGlobalSelectedAsset(this.selectedAssetData.assetcode);
    this.selectedAssetData.asset =
      this.selectedAssetData.asset == 'Tandem'
        ? 'tandem'
        : this.selectedAssetData.asset;
    if (event.asset) {
      this.subject.setSelectedAssetData(event);
      this.router.navigate(['page/assetgrid/assetdetails', event.id]);
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    }
    this.getRunningHrs();
    this.loadImage();
    this.setAssetCustomName();
    this.loadAlertData().then(() => {});
    this.loadAssetTags();
    this.loadWidgetData();
    this.loadWidgetListData();
    this.getChartData(this.aggregateFrame);
  }
  changeKPI(kpi: any) {
    this.selectedKpi = kpi;
    this.details_kpi = {
      asset: this.selectedAssetData,
      date: this.selectedFilter,
      KPI: this.selectedKpi,
    };
  }
  tagDisplay(data: any) {
    let tags: string = '';
    //if(typeof data.tag_info=="string")
    if (typeof data.tag_info == 'string') {
      data.tag_info = JSON.parse(data.tag_info);
    }
    for (let t = 0; t < data.tag_info.length; t++) {
      if (data.tag_info[t]) {
        tags += data.tag_info[t] + ', ';
      }
    }
    return tags.slice(0, -2);
  }

  changeAssetTag(event: any, type: string) {
    //this.addWidgetObj["tag_info"] = event;
    this.selectedAssetWidgetTags = event;

    let tags: any = [];
    if (type == 'multiple') {
      event.forEach((element: any) => {
        tags.push(element.tag);
      });
    } else {
      tags.push(event.tag);
    }
    this.addWidgetObj['tag_info'] = JSON.stringify(tags);
    let array = this.widgetForm.controls['tag_info'] as FormArray;
    array.clear();
    tags.forEach((t) => {
      array.push(this.formBuilder.control(t));
    });
  }

  changeWidgetTag(event: any, type: string, index: number) {
    this.addWidgetObj.options.tagMapping[index].tag = event;
  }

  widgetonSubmit() {}

  loadAssetTags() {
    if (this.selectedAssetData) {
      let filter = [
        {
          key: 'assetcode',
          symbol: '==',
          value: this.selectedAssetData.assetcode,
        },
        {
          key: 'removedtag',
          symbol: '==',
          value: false,
        },
      ];
      let tagsParams = {
        category: 'THRESHOLDS',
        conditions: filter,
      };
      this.subscription_tagData = this.fs
        .getData(tagsParams)
        .subscribe((response: any) => {
          if (response.status == 'success') {
            this.tagData = response.data;
            // this.tagData.forEach((element: any) => {
            //   element.component = element.component.replace(/_/g, ' ');
            // });
          }
        });
    }
  }
  loadAlertData() {
    return new Promise((resolve, reject) => {
      if (this.selectedAssetData) {
        // let filterObj = {
        //   assetcode: this.selectedAssetData["assetcode"],
        //   timestamp: {
        //     gte: moment(this.startDate).unix() * 1000,
        //     lt: moment(this.endDate).unix() * 1000
        //   },
        // }
        let filter = [
          {
            key: 'assetcode',
            symbol: '==',
            value: this.selectedAssetData['assetcode'],
          },
          {
            key: 'state',
            symbol: '==',
            value: 'new',
          },
          {
            key: 'timestamp',
            symbol: '>=',
            value: moment().subtract(3, 'days').startOf('day').toDate(),
          },
          {
            key: 'timestamp',
            symbol: '<=',
            value: moment().toDate(),
          },
        ];
        let warnParams = {
          category: 'ALERTS',
          conditions: filter,
        };
        this.subscription_alertData = this.fs
          .getData(warnParams)
          .subscribe((response: any) => {
            if (response.status == 'success') {
              this.alertData = response.data;
              this.alertData = _.orderBy(
                this.alertData,
                [
                  (val) => {
                    return val.timestamp._seconds;
                  },
                  'Date',
                ],
                ['desc', 'desc']
              );
              this.alertData.forEach((element: any) => {
                element.date = new Date(element.timestamp);
              });
              resolve(true);
            }
          });
      } else {
        reject(false);
      }
    });
  }

  loadLiveTags() {
    let params = {
      category: 'CURRENTDATA',
      conditions: [
        {
          key: 'assetcode',
          symbol: '==',
          value: this.selectedAssetData.assetcode,
        },
        {
          key: 'asset',
          symbol: '==',
          value: this.selectedAssetData.asset,
        },
      ],
    };
    this.liveDataSubscription = this.fs
      .getData(params)
      .subscribe((response: any) => {
        if (response.status == 'success') {
          this.liveData = response.data;
          this.liveData.forEach((element: any) => {
            this.liveTagData = _.unionBy(this.liveTagData, element.tags, 'tag');
          });
        }
      });
  }

  loadWidgetListData() {
    if (this.selectedAssetData) {
      let filter = [
        {
          key: 'assetcode',
          symbol: '==',
          value: this.selectedAssetData.assetcode,
        },
        {
          key: 'screen',
          symbol: '==',
          value: this.sidebarDropdownSelected.title,
        },
      ];
      let chartParams = {
        category: 'CHARTINFO',
        conditions: filter,
      };
      this.fs.getData(chartParams).subscribe((response: any) => {
        this.widgetListData = [];
        if (response.status == 'success') {
          this.widgetListData = response.data;
          this.widgetListData.sort(function (a, b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });
          // this.prepareWidgetParams();
        }
      });
    }
  }

  loadWidgetData() {
    if (this.selectedAssetData) {
      let queryFilter = [
        {
          key: 'assetcode',
          symbol: '==',
          value: this.selectedAssetData.assetcode,
        },
        // {
        //   key: "asset",
        //   symbol: "==",
        //   value: this.selectedAssetData.asset
        // },
        {
          key: 'screen',
          symbol: '==',
          value: this.sidebarSelected.title,
        },
      ];
      if (this.selectedTabs && this.selectedTabs.title) {
        let filterQuery = queryFilter.filter((filterdata) => {
          return filterdata.key == 'screen';
        });
        if (filterQuery.length > 0) {
          filterQuery[0].value = this.selectedTabs.title;
        }
      }
      let chartParams = {
        category: 'CHARTINFO',
        conditions: queryFilter,
      };
      this.fs.getData(chartParams).subscribe((response: any) => {
        //this.fs.getAssetCharts({ asset_code: this.selectedAssetData.assetcode }).subscribe((data) => {
        this.widgetData = [];
        if (response.status == 'success') {
          // response.data.forEach(element => {
          //   let widgetInfo: any = element.data();
          //   let widgetObj = { id: element.id }
          //   for (let key in widgetInfo) {
          //     widgetObj[key] = widgetInfo[key];
          //   }
          //   this.widgetData.push(widgetObj);
          // });
          this.widgetData = response.data;
          this.widgetData.sort(function (a, b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });
          this.prepareWidgetParams();
          let filterChart = this.widgetData.filter((data) => {
            data['loading'] = true;
            return data.category == 'CHART';
          });
          if (filterChart.length > 0) {
            this.util.setLoaderValue(true);
            this.getChartData('HISTORY');
          } else {
            this.util.setLoaderValue(false);
          }
        }
      });
    }
  }

  prepareWidgetParams() {
    this.widgetData.forEach((element: any) => {
      if (typeof element.tag_info == 'string') {
        element.tag_info = JSON.parse(element.tag_info);
      }
      for (let t = 0; t < element.tag_info.length; t++) {
        if (
          !this.selectedWidgetTags[element.tag_info[t]] &&
          element.tag_info[t]
        ) {
          this.selectedWidgetTags[element.tag_info[t]] = [];
        }
      }

      if (element.category == 'CHART') {
        if (!element.chart_data) element.chart_data = [];
        element.live = this.selectedFilter_graph_data == 'Live' ? true : false;
        element.chartname = element.title.toLowerCase().replace(/ /g, '_');
        element.action = 'VIEW';
      } else if (element.category == 'SINGLE_CARD') {
        element.tagData = [];
        element.tag_info.forEach((tag: string) => {
          let filterTag = this.liveTagData.filter((liveData) => {
            return liveData.tag == tag;
          });
          element.tagData.push(filterTag[0]);
        });
      } else if (
        element.category == 'LIST_CARD' ||
        element.category == 'GRID_CARD'
      ) {
        if (
          element.options &&
          element.options.tagMapping &&
          element.options.tagMapping.length > 0
        ) {
          element.options.tagMapping.forEach((tagelement: any) => {
            let filterTag = this.liveTagData.filter((liveData) => {
              return liveData.tag == tagelement.tag;
            });
            tagelement.data = filterTag[0];

            let filterTagData = this.tagData.filter((data: any) => {
              return data.tag == tagelement.tag;
            });
            if (filterTagData.length > 0) {
              tagelement.tag = filterTagData[0];
            }
          });
        }
      }
      //this.liveTagData
    });
  }

  widgetAction(data: any, type: string) {
    data.action = type;
    if (type == 'EDIT') {
      this.widgetButton = 'EDIT';
      let selectedTags: any = [];
      data.tag_info.forEach((element) => {
        let filterTag = this.tagData.filter((ftag) => {
          return ftag.tag == element;
        });
        if (filterTag.length > 0) {
          selectedTags.push(filterTag[0]);
        }
      });
      if (
        data.category == 'CHART' ||
        data.category == 'LIST_CARD' ||
        data.category == 'GRID_CARD'
      ) {
        this.selectedAssetWidgetTags = selectedTags;
      } else if (data.category == 'SINGLE_CARD') {
        this.selectedAssetWidgetTags = selectedTags[0];
      }

      let filterCategory = this.widgetCategory.data.filter((categoryData) => {
        return categoryData.id == data.category;
      });
      this.widgetCategory.selected = filterCategory[0];
      this.chartType.selected = data.chartType || "Spline";
      this.userSpecific.selected = data.userSpecific || "All Users";
      this.addWidgetObj = {
        id: data.id,
        title: data.title,
        tag_info: selectedTags,
        asset: this.selectedAssetData.asset,
        assetcode: this.selectedAssetData.assetcode,
        query_action: 'update',
        width: data.width,
        options: data.options,
        createdBy: data.createdBy,
        timestamp:moment().toDate()
      };
      if (!this.addWidgetObj.options) this.addWidgetObj.options = {};
      if (!this.addWidgetObj.options.tagMapping)
        this.addWidgetObj.options.tagMapping = [];
      let width = this.widthList.data.filter((d) => {
        return d.value == data.width;
      });
      this.widthList.selected = width[0];
      //this.formulaArray.controls = data.formulas;
      if (data.category == 'CHART') {
        this.widgetForm.setValue({
          formulas: { PRE: data.formulas },
          title: '',
          tag_info: '',
          width: '',
        });
        this.widgetForm.patchValue({
          formulas: { PRE: data.formulas },
          title: '',
          tag_info: '',
          width: '',
        });
      }
    } else if (type == 'DELETE') {
      this.addWidgetObj.id = data.id;
      this.addWidgetObj.query_action = 'delete';

      /*****************FS****************** */
      this.fs.fireStoreDocDelete('CHARTINFO', this.addWidgetObj.id).subscribe(
        () => {
          this.widgetButton = 'ADD';
          if (this.addWidgetObj.query_action == 'delete') {
            let filterWidget = this.widgetData.filter((data) => {
              return data.id == this.addWidgetObj.id;
            });
            if (filterWidget.length > 0) {
              let index = this.widgetData.indexOf(filterWidget[0]);
              if (index > -1) {
                this.widgetData.splice(index, 1);
              }
            }
            let filterWidget1 = this.widgetListData.filter((data) => {
              return data.id == this.addWidgetObj.id;
            });
            if (filterWidget1.length > 0) {
              let index = this.widgetListData.indexOf(filterWidget1[0]);
              if (index > -1) {
                this.widgetListData.splice(index, 1);
              }
            }
          }
          this.addWidgetObj.query_action = '';
          this.selectedAssetWidgetTags = [];
          this.addWidgetObj = {
            title: '',
            tag_info: [],
            assetcode: this.selectedAssetData.assetcode,
            asset: this.selectedAssetData.asset,
            query_action: 'insert',
          };
          this.widthList.selected = {};
          this.widgetForm.reset();
          this.util.showSnakbarMessage(
            'success',
            'Widget Deleted Sucsessfully!',
            'end',
            'top'
          );
        },
        (err) => {
          this.util.showSnakbarMessage(
            'error',
            'Chart Config Deletion Failed',
            'end',
            'top'
          );
        }
      );
    }
  }

  setView() {}

  open(content: any, type: string, data?) {
    let modalOptions = {};
    if (type == 'ADDWIDGET') {
      this.selectedAssetWidgetTags = [];
      this.addWidgetObj = {
        title: '',
        tag_info: [],
        asset: this.selectedAssetData.asset,
        assetcode: this.selectedAssetData.assetcode,
        query_action: 'insert',
        width: '',
        options: {},
      };
      if (!this.addWidgetObj.options) this.addWidgetObj.options = {};
      if (!this.addWidgetObj.options.tagMapping)
        this.addWidgetObj.options.tagMapping = [];
      this.addWidgetObj.options.tagMapping.push({ label: '', tag: '' });
      this.widthList.selected = {};
      this.widgetForm.reset();
      modalOptions = {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        backdrop: 'static',
      };
    } else if (type == 'CHARTPOPUP') {
      modalOptions = { size: 'xl' };
      this.selectedPopupChart = data;
    } else if (type == 'ALERT') {
    }

    this.modalService.open(content, modalOptions).result.then(
      (result) => {
        //this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  addWidget(type?: string) {
    if (this.addWidgetObj.title && this.addWidgetObj.tag_info) {
      // let tagArr:any = [];
      // this.addWidgetObj.tag_info.forEach(element => {
      //   tagArr.push(element.tag);
      // });
      // this.addWidgetObj.tag_info = JSON.stringify(this.addWidgetObj.tag_info);

      if (typeof this.addWidgetObj.tag_info != 'string') {
        let tags: any = [];
        this.addWidgetObj.tag_info.forEach((element: any) => {
          tags.push(element.tag);
        });
        this.addWidgetObj['tag_info'] = JSON.stringify(tags);
      }

      if (
        this.addWidgetObj.options &&
        this.addWidgetObj.options.tagMapping &&
        this.addWidgetObj.options.tagMapping.length > 0
      ) {
        this.addWidgetObj.options.tagMapping.forEach((element: any) => {
          let temp = Object.assign({}, element.tag);
          element.tag = temp.tag;
        });
      }

      if (
        this.addWidgetObj.query_action == '' ||
        this.addWidgetObj.query_action == 'insert'
      ) {
        let insertData: any = {
          asset: this.addWidgetObj.asset,
          assetcode: this.addWidgetObj.assetcode,
          tag_info: this.addWidgetObj.tag_info,
          title: this.addWidgetObj.title,
          width: this.widthList.selected.value,
          order: this.widgetData.length + 1,
          category: this.widgetCategory.selected.id,
          screen: this.sidebarDropdownSelected.title,
          options: this.addWidgetObj.options,
          createdBy:this.loggedinUser.email,
          timestamp:moment().toDate()
        };
        if(this.widgetCategory.selected.id=="CHART") {
          insertData.chartType = this.chartType.selected;
        }
        if(this.userSpecific.selected=="Current User") {
          insertData.userSpecific = "Current User";
          insertData.email = this.loggedinUser.email;
        }
        if (
          this.widgetForm.value.formulas &&
          this.widgetForm.value.formulas.PRE &&
          this.widgetForm.value.formulas.PRE[0].formula &&
          this.widgetForm.value.formulas.PRE[0].unit
        ) {
          insertData.formulas = this.widgetForm.value.formulas.PRE;
        }
        this.fs.fireStoreDocInsert('CHARTINFO', insertData).subscribe(
          (response: any) => {
            if (response.status == 'success') {
              this.getChartData(this.aggregateFrame);
              this.widgetButton = 'ADD';
              this.addWidgetObj.width = this.widthList.selected.value;
              // this.widgetData.push(this.addWidgetObj);
              // this.widgetListData.push(this.addWidgetObj);
              this.widgetData.push(insertData);
              this.widgetListData.push(insertData);
              this.util.showSnakbarMessage(
                'success',
                'Widget Created Sucsessfully!',
                'end',
                'top'
              );
              if (this.widgetButton == 'ADD') {
                this.selectedAssetWidgetTags = [];
                this.addWidgetObj = {
                  title: '',
                  tag_info: [],
                  assetcode: this.selectedAssetData.assetcode,
                  asset: this.selectedAssetData.asset,
                  query_action: 'insert',
                };
                this.widthList.selected = {};
                this.widgetForm.reset();
              }
              if (
                this.addWidgetObj.options &&
                this.addWidgetObj.options.tagMapping &&
                this.addWidgetObj.options.tagMapping.length > 0
              ) {
                this.addWidgetObj.options.tagMapping.forEach((element: any) => {
                  let filterTagData = this.tagData.filter((data: any) => {
                    return data.tag == element.tag;
                  });
                  if (filterTagData.length > 0) {
                    element.tag = filterTagData[0];
                  }
                });
              }
              this.prepareWidgetParams();
              this.getChartData('HistoryData');
            } else {
            }
          },
          (err) => {
            this.util.showSnakbarMessage(
              'success',
              'Widget Creation Failed!',
              'end',
              'top'
            );
          }
        );
      } else if (this.addWidgetObj.query_action == 'update') {
        let updateData: any = {
          asset: this.addWidgetObj.asset,
          assetcode: this.addWidgetObj.assetcode,
          tag_info: this.addWidgetObj.tag_info,
          title: this.addWidgetObj.title,
          width: this.widthList.selected.value,
          options: this.addWidgetObj.options,
          category: this.widgetCategory.selected.id,
          screen: this.sidebarDropdownSelected.title,
          createdBy:this.addWidgetObj.createdBy || this.loggedinUser.email,
          timestamp:moment().toDate()
        };
        if(this.widgetCategory.selected.id=="CHART") {
          updateData.chartType = this.chartType.selected;
        }
        if(this.userSpecific.selected=="Current User") {
          updateData.userSpecific = "Current User";
          updateData.email = this.loggedinUser.email;
        }
        if (
          this.widgetForm.value.formulas.PRE.formula &&
          this.widgetForm.value.formulas.PRE.unit
        ) {
          updateData.formulas = this.widgetForm.value.formulas.PRE;
        }
        this.fs
          .fireStoreDocUpdate('CHARTINFO', this.addWidgetObj.id, updateData)
          .subscribe(
            () => {
              this.getChartData(this.aggregateFrame);
              this.widgetButton = 'ADD';
              let filterWidget = this.widgetData.filter((data) => {
                return data.id == this.addWidgetObj.id;
              });
              if (filterWidget.length > 0) {
                filterWidget[0].title = this.addWidgetObj.title;
                filterWidget[0].tag_info = this.addWidgetObj.tag_info;
                filterWidget[0].width = this.widthList.selected.value;
                filterWidget[0].formulas = this.widgetForm.value.formulas.PRE;
              }
              let filterWidget1 = this.widgetListData.filter((data) => {
                return data.id == this.addWidgetObj.id;
              });
              if (filterWidget1.length > 0) {
                filterWidget1[0].title = this.addWidgetObj.title;
                filterWidget1[0].tag_info = this.addWidgetObj.tag_info;
                filterWidget1[0].width = this.widthList.selected.value;
                filterWidget1[0].formulas = this.widgetForm.value.formulas.PRE;
              }

              this.util.showSnakbarMessage(
                'success',
                'Widget Updated Sucsessfully!',
                'end',
                'top'
              );
              if (this.widgetButton == 'ADD') {
                this.selectedAssetWidgetTags = [];
                this.addWidgetObj = {
                  title: '',
                  tag_info: [],
                  asset: this.selectedAssetData.asset,
                  assetcode: this.selectedAssetData.assetcode,
                  query_action: 'insert',
                };
                this.widthList.selected = {};
              }
              if (
                this.addWidgetObj.options &&
                this.addWidgetObj.options.tagMapping &&
                this.addWidgetObj.options.tagMapping.length > 0
              ) {
                this.addWidgetObj.options.tagMapping.forEach((element: any) => {
                  let filterTagData = this.tagData.filter((data: any) => {
                    return data.tag == element.tag;
                  });
                  if (filterTagData.length > 0) {
                    element.tag = filterTagData[0];
                  }
                });
              }
              this.prepareWidgetParams();
              this.getChartData('HistoryData');
            },
            (err) => {
              this.util.showSnakbarMessage(
                'success',
                'Widget Updation Failed!',
                'end',
                'top'
              );
            }
          );
      }
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.widgetData, event.previousIndex, event.currentIndex);
    moveItemInArray(
      this.widgetListData,
      event.previousIndex,
      event.currentIndex
    );
    let widgetOrderArr: any = [];
    this.widgetListData.forEach((element, index) => {
      element.order = index;
      let orderObj: any = {
        id: element.id,
        order: element.order,
      };
      widgetOrderArr.push(orderObj);
    });

    if (environment.mode == 'firebase') {
      let params: any = {
        category: 'CHARTINFO',
        orderArr: JSON.stringify(widgetOrderArr),
      };
      this.fs.saveWidgetOrder(params).subscribe(
        (response: any) => {
          if (response.status == 'success') {
            this.util.showSnakbarMessage(
              'success',
              'Widget Order Updated Successfully!',
              'end',
              'top'
            );
          } else {
            this.util.showSnakbarMessage(
              'error',
              'Widget Order Updation Failed!',
              'end',
              'top'
            );
          }
        },
        (err) => {
          console.log(err.message);
        }
      );
    } else {
      this.http
        .saveWidgetOrder({ orderArr: JSON.stringify(widgetOrderArr) })
        .subscribe(
          (response) => {
            // console.log(response);
          },
          (err) => {
            console.log(err.message);
          }
        );
    }
  }

  /************************************************************** */
  getChartData(collection: string, isIndividual?: boolean, widget_data?: any) {
    if (this.selectedAssetData) {
      if (!isIndividual) {
        this.util.setLoaderValue(true);
      }
      this.historyResponse = [];
      let params = {};
      if (collection == 'HISTORY') {
        //  params = {
        //     "category":collection,
        //     "customerId": "BLP",
        //     "assetcode": this.selectedAssetData.assetcode,
        //     "asset": this.selectedAssetData.asset,
        //     "startTime": moment(this.startDate).unix(),
        //     "endTime": moment(this.endDate).unix()
        //    }
        params = {
          //  "category":collection,
          //  "customerId": "BLP",
          //  "assetcode": this.filter.asset.selected.assetcode,
          //  "asset": this.filter.asset.selected.asset,
          //  "startTime": moment(this.startDate).unix(),
          //  "endTime": moment(this.endDate).unix(),
          fromDate: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
          toDate: moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
          asset_code: [this.selectedAssetData.assetcode],
          tag_name: !isIndividual ? 'All' : widget_data['tag_info'],
          collection: collection,

          //  "tags":this.filter.raw.selected
        };
        // for(let tags of this.filter.raw.selected){
        //   params['tag_name'].push(tags.tag)
        // }
      } else {
        // params = {
        //   "category":"AGGREGATE",
        //   "collection":`/Asset_OEE/JSPL/${collection}/wind/${this.selectedAssetData.assetcode}`,
        //   "conditions":[
        //   {
        //   "key": "timestamp",
        //   "symbol": ">=",
        //   "value": this.startDate
        //   },
        //   {
        //   "key": "timestamp",
        //   "symbol": "<=",
        //   "value": this.endDate
        //   }
        //   ]
        //  }
        params = {
          fromDate: moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'),
          toDate: moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'),
          asset_code: [this.selectedAssetData.assetcode],
          interval: collection,
          tag_name: !isIndividual ? 'All' : widget_data['tag_info'],
          collection: collection,
        };
      }
      this.initialDataSubscription = this.fs
        .getAgrregatedData(params)
        .then((element: any) => {
          widget_data ? (widget_data.loading = false) : '';
          if (element.Data?.length > 0) {
            this.error = '';
            this.historyResponse = element.Data;
            this.historyResponse.forEach((d) => {
              d['messageid'] = moment(d.timestamp)
                .subtract(330, 'minutes')
                .unix();
            });

            if (this.historyResponse.length > 0) {
              let assetResult = _.orderBy(
                this.historyResponse,
                ['messageid'],
                ['asc']
              );
              this.segragate_datapoints(assetResult, widget_data);
            }
          } else {
            this.error = 'No Data Available';
          }
        })
        .catch((err) => {
          widget_data ? (widget_data.loading = false) : '';
          this.util.setLoaderValue(false);
          this.error = 'Erro Loading Data';
          console.log(err);
          this.router.navigate(['login']);
        });
      //this.getindicatorsdata();
    }
    this.util.setLoaderValue(false);
  }

  segragate_datapoints(data: any, widget_data?: any) {
    // data.forEach((d: any, index: number) => {
    //this.xaxis.push(moment(d.timestamp.seconds * 1000).format("DD/MMM/YYYY, HH:mm:ss"));
    if (widget_data) {
      this.xaxis = [];
    }
    for (let tag in this.selectedWidgetTags) {
      widget_data ? '' : (this.xaxis = []);
      let filterTagData = data.filter((data: any) => {
        return data.tag_name == tag || data['component'] == tag;
      });
      if (filterTagData && filterTagData.length > 0) {
        for (let tagsData of filterTagData) {
          let xaxis_point = moment
            .unix(tagsData.messageid)
            .format('DD/MMM/YYYY, HH:mm:ss');
          if (this.xaxis.indexOf(xaxis_point) == -1) {
            this.xaxis.push(xaxis_point);
          }
          let filterTagValue = _.filter(this.selectedWidgetTags[tag], {
            x: tagsData.messageid * 1000,
          });
          if (filterTagValue && filterTagValue.length == 0) {
            //this.selectedWidgetTags[tag].push({ x: new Date(d.timestamp.seconds * 1000).getTime(), y: typeof filterTagData[0].value == "string" ? parseFloat(filterTagData[0].value) : filterTagData[0].value });
            this.selectedWidgetTags[tag].push({
              x: tagsData.messageid * 1000,
              y:
                typeof tagsData.tag_value == 'string'
                  ? parseFloat(tagsData.tag_value)
                  : tagsData.tag_value || 0,
            });
          }
        }
      }
    }
    // })
    //if (state == "live") {

    this.widgetData.forEach((element: any) => {
      if (element.category == 'CHART') {
        element.loading = false;
        let obj: any = {
          chartname: element.title.toLowerCase().replace(/ /g, '_'),
          type: element.chartType,
          th: 0,
          xaxis: this.xaxis,
          unit: '',
          label_y: '',
          livetag: 'dm_temperature',
          y_interval: 10,
          tag_info: element.tag_info,
        };
        if (element.tag_info.length == 1) element.tag_info.push('');

        if (
          element.formulas &&
          element.formulas.length > 0 &&
          element.formulas[0].formula
        ) {
          element.formulaData = [];
          element.formulaTempData = {};
          let xaxisPoint: any = [];
          for (let t = 0; t < element.tag_info.length; t++) {
            if (element.tag_info[t]) {
              let tagData = this.selectedWidgetTags[element.tag_info[t]];
              tagData.forEach((ele1: any) => {
                if (!element.formulaTempData[ele1.x])
                  element.formulaTempData[ele1.x] = {};
                element.formulaTempData[ele1.x][element.tag_info[t]] = ele1.y;
              });
            }
          }
          element.formulas.forEach((formulaData) => {
            if (formulaData.formula) {
              for (let x in element.formulaTempData) {
                let formula = JSON.parse(JSON.stringify(formulaData.formula));
                for (let y in element.formulaTempData[x]) {
                  formula = formula.replace(y, element.formulaTempData[x][y]);
                }
                let y = new Function('return ' + formula)();
                y = y.toString();
                if (y.indexOf('.') > -1) {
                  y = parseFloat(parseFloat(y).toFixed(2));
                } else {
                  y = parseInt(y);
                }
                element.formulaData.push({ x: x, y: y });
              }
            }
          });
          obj['unit'] =
            element.formulas[0].axisName + '(' + element.formulas[0].unit + ')';
          obj['label_y'] =
            element.formulas[0].axisName + '(' + element.formulas[0].unit + ')';
          obj['name1'] = element.formulas[0].axisName;
          obj['data1'] = element.formulaData;
          obj['datapoint1'] = '';
          obj['category'] = 'formulaChart';
        } else {
          for (let t = 0; t < element.tag_info.length; t++) {
            if (element.tag_info[t]) {
              let filterTagData = this.tagData.filter((data: any) => {
                return data.tag == element.tag_info[t];
              });
              if (filterTagData[0]?.unit === '�C') filterTagData[0].unit = 'C';
              obj['unit'] = filterTagData[0]?.unit;
              obj['label_y'] = filterTagData[0]?.unit;
              obj['name' + (t + 1)] = element.tag_info[t]
                ? filterTagData[0]?.component
                : '';
            } else {
              obj['name' + (t + 1)] = '';
            }

            obj['data' + (t + 1)] = element.tag_info[t]
              ? this.selectedWidgetTags[element.tag_info[t]]
              : [];
            obj['datapoint' + (t + 1)] = element.tag_info[t]
              ? element.tag_info[t]
              : '';
          }
        }

        this.chartdata(obj);
        this.util.setLoaderValue(false);
        element.chart_data.push(
          this.createchartdata(this.HistoryChartObj[obj.chartname])[0]
        );
      }
      this.util.setLoaderValue(false);
    });
    // } else if (state.state == "history") {
    //   ////console.log("history");
    //   if (this.datapoints[this.HistoryChartObj[this.chartname].datapoint2] == "") {
    //     this.historydata(this.datapoints[this.HistoryChartObj[this.chartname].datapoint1], undefined);
    //   }
    // } else {
    //   this.historydata(this.datapoints[this.HistoryChartObj[this.chartname].datapoint1], this.datapoints[this.HistoryChartObj[this.chartname].datapoint2]);
    // }
  }

  chartdata(data: any) {
    let th: any = [];
    let unit: string;
    if (!this.HistoryChartObj[data.chartname])
      this.HistoryChartObj[data.chartname] = {};
    (this.HistoryChartObj[data.chartname]['xaxis'] = this.xaxis),
      (this.HistoryChartObj[data.chartname]['th'] = th),
      (this.HistoryChartObj[data.chartname]['unit'] = data.unit),
      (this.HistoryChartObj[data.chartname]['livetag'] = data.livetag),
      (this.HistoryChartObj[data.chartname]['label_y'] = data.label_y),
      (this.HistoryChartObj[data.chartname]['y_interval'] = data.y_interval);
    this.HistoryChartObj[data.chartname]['tag_info'] = data.tag_info;
    this.HistoryChartObj[data.chartname]['type'] = data.type;
    this.HistoryChartObj[data.chartname]['chartname'] = data.chartname;
    this.HistoryChartObj[data.chartname]['category'] = !data.category
      ? 'chart'
      : data.category;

    data.tag_info.forEach((chartData: any, index: number) => {
      let i = index + 1;
      this.HistoryChartObj[data.chartname]['name' + i] = data['name' + i];
      this.HistoryChartObj[data.chartname]['data' + i] = data['data' + i];
      this.HistoryChartObj[data.chartname]['datapoint' + i] =
        data['datapoint' + i];

      if (chartData) {
        let thresholdFilter = this.tagData.filter((tag: any) => {
          return tag.tag == data['datapoint' + i];
        });
        if (thresholdFilter.length > 0) {
          let t = thresholdFilter[0];
          th.push({
            color:
              parseInt(t.upper) == 0 || parseInt(t.upper) == 999999
                ? 'transparent'
                : 'transparent',
            width: 1,
            value: parseInt(t.upper) == 999999 ? 0 : parseInt(t.upper),
            zIndex: 5,
            label: {
              align: 'center',
              formatter: undefined,
              rotation: undefined,
              style: {
                fontSize: '10px',
                color:
                  parseInt(t.upper) == 0 || parseInt(t.upper) == 999999
                    ? 'transparent'
                    : 'transparent',
              },
              text: `Upper Threshold ${t.upper} - ${t.unit}`,
              textAlign: 'center',
              useHTML: false,
              verticalAlign: 'top',
              x: undefined,
              // y:-30,
            },
          });
        }
      }
    });
  }

  historydata(data1: any, data2?: any) {
    this.HistoryChartObj[this.chartname].data1 = data1;
    this.HistoryChartObj[this.chartname].xaxis = this.xaxis;
    (this.HistoryChartObj[this.chartname].data2 = data2 ? data2 : undefined),
      (this.history_chart = this.createchartdata(
        this.HistoryChartObj[this.chartname]
      ));
  }

  createchartdata(Data: any) {
    //  let Data = this.obj;
    const final_array: any = [];
    let y_current: any = [];
    let yAxis: any[] = [];
    let self = this;
    let chartColorArr = [
      '#427bff',
      '#FFC300',
      '#8750C9',
      '#BA38C2',
      '#00CADD',
      '#00B9FB',
      '#5E68C5',
    ];

    if (Data.category == 'formulaChart') {
      let y = {
        title: {
          enabled: true,
          text: Data.unit,
          style: {
            color: chartColorArr[0],
          },
        },
        labels: {
          enabled: false,
          style: {
            color: chartColorArr[0],
          },
        },
      };
      yAxis.push(y);
      y_current.push({
        color: chartColorArr[0],
        // threshold: (Data.th != undefined && Data.th.length > 0) ? Data.th[0].value : null,
        marker: {
          enabled: false,
        },
        name: Data['name1'],
        data: (function () {
          var data: any = [],
            time = new Date().getTime(),
            x,
            y,
            i = -Data['data1'].length;
          for (i; i < 0; i += 1) {
            y = Data['data1'][i + Data['data1'].length].y;
            x = Data.xaxis[i + Data.xaxis.length];
            let time2 = new Date(x).getTime();
            data.push({
              // x: time + i * 1000,
              x: time2,
              y: y,
            });
          }
          return data;
        })(),
      });
    } else {
      Data.tag_info.forEach((element: any, index: number) => {
        if (element) {
          let y = {
            title: {
              enabled: index == 0 ? true : false,
              text: Data.unit,
              style: {
                color: chartColorArr[index],
              },
            },
            labels: {
              enabled: false,
              style: {
                color: chartColorArr[index],
              },
            },
          };
          yAxis.push(y);
          let l = index + 1;
          y_current.push({
            color: chartColorArr[index],
            // threshold: (Data.th != undefined && Data.th.length > 0) ? Data.th[0].value : null,
            marker: {
              enabled: false,
            },
            dataLabels: [
              {
                enabled: false,
              },
            ],
            name: Data['name' + l],
            yAxis: index,
            data: (function () {
              var data: any = [],
                time = new Date().getTime(),
                x,
                y,
                i = -Data['data' + l].length;
              for (i; i < 0; i += 1) {
                y = Data['data' + l][i + Data['data' + l].length].y;
                x = Data.xaxis[i + Data.xaxis.length];
                let time2 = new Date(x).getTime();
                data.push({
                  // x: time + i * 1000,
                  x: time2,
                  y: y,
                });
              }
              return data;
            })(),
          });
        }
      });
    }

    final_array.push({
      type:Data.type,
      chartname: Data.chartname,
      xaxis: Data.xaxis,
      yaxis: y_current,
      y: yAxis,
      label_y: Data.label_y,
      threshold: Data.th,
      accessibility: {
        announceNewData: {
          enabled: true,
          minAnnounceInterval: 15000,
          announcementFormatter: function (allSeries, newSeries, newPoint) {
            if (newPoint) {
              return 'New point added. Value: ' + newPoint.y;
            }
            return false;
          },
        },
      },
      livedata: {
        load: function (chartObj) {
          let chartOptions = chartObj;
          self.subject.updatedgraphdata.subscribe((value) => {
            if (
              chartOptions.target.title &&
              chartOptions.target.title.textStr == Data.chartname
            ) {
              let filterState = self.widgetData.filter((widget) => {
                return widget.chartname == Data.chartname;
              });
              if (filterState.length > 0) {
                if (filterState[0].live) {
                  for (let t = 0; t < Data.tag_info.length; t++) {
                    if (Data.tag_info[t]) {
                      let seriesData = chartOptions.target.series[t];
                      let seriesName = seriesData.name;
                      let dataTagName = Data['name' + (t + 1)];
                      if (seriesName == dataTagName) {
                        let valueData = value[Data.tag_info[t]];
                        if (valueData) {
                          if (seriesData.chart.xAxis[0].displayBtn) {
                            seriesData.chart.xAxis[0].setExtremes(
                              seriesData.chart.xAxis[0].min +
                                seriesData.chart.xAxis[0].minRange,
                              seriesData.xData[Data.data1.length]
                            );
                          }
                          //seriesData.addPoint({ x: valueData.x, y: valueData.y }, true, true);
                          let shift = seriesData.data.length > 20;
                          seriesData.addPoint(
                            { x: valueData.x, y: valueData.y },
                            true,
                            shift
                          );
                        }
                      }
                    }
                  }
                }
              }
            }
          });
          // self.service.updatePlotBands.subscribe(value => {
          //   var plotBand = chartObj.xAxis[0].plotLinesAndBands[1];
          //   if (plotBand.hidden)
          //   {
          //     plotBand.hidden = false;
          //     plotBand.svgElem.show();
          //   } else
          //   {
          //     plotBand.hidden = true;
          //     plotBand.svgElem.hide();
          //   }
          //   console.log(chart)
          // })
        },
      },
      mr: 25,
      mt: 20,
      ml: 60,
      mb: 70,
      min_y: 0,
      unit: Data.unit,
      format: true,
      tickinterval_y: Data.y_interval,
      tickinterval_x: 10,
    });
    // console.log(final_array)
    return final_array;
  }

  socketconnection() {
    this.subject.setupSocketConnection();
    this.historySubcription = this.subject
      .getHistoryUpdateListener()
      .subscribe((historyUpdate: any) => {
        //let historyUpdate = this.selectedAssetData;
        if (historyUpdate && historyUpdate.messageid) {
          this.subject.setLastUpdatedDate(historyUpdate.messageid);
        }

        if (this.historyResponse && this.historyResponse.length > 0) {
          if (historyUpdate.asset == this.selectedAssetData.asset) {
            this.historyResponse.shift();
            this.historyResponse.push(historyUpdate);
            if (this.populate_live_data) {
              this.liveupdate(historyUpdate);
            }
            this.updatechartForAsset(historyUpdate, this.widgetData);
          }
        }
      });
  }

  updatechartForAsset(data, widgetData) {
    let x;
    x = data.messageid * 1000;
    let obj = {};
    widgetData.forEach((element) => {
      element.tag_info.forEach((tag) => {
        if (tag) {
          let filterTagData = data.tags.filter((tagdata) => {
            return tagdata.tag == tag;
          });
          if (filterTagData.length > 0) {
            obj[tag] = {
              x: x,
              y:
                typeof filterTagData[0].value == 'string'
                  ? parseFloat(filterTagData[0].value)
                  : filterTagData[0].value || 0,
              state: element.live,
            };
          }
        }
      });
    });
    if (Object.keys(obj).length > 0) {
      this.subject.sendupdatedgraphdata(obj);
    }
  }

  liveupdate(livedata) {
    // if (environment.SERVICE_CALL == "http")
    // {
    //   this.livetime = moment(livedata.timestamp).format("DD-MMM-YYYY, HH:mm:ss");
    // } else
    // {
    //   this.livetime = moment(livedata.timestamp.seconds * 1000).format("DD-MMM-YYYY, HH:mm:ss");
    // }
    // livedata.tags.forEach(tag => {
    //   if (this.HistoryChartObj[this.chartname]["multiple"])
    //   {
    //     this.liveObj["multiple"] = this.HistoryChartObj[this.chartname]["multiple"];
    //     this.liveObj["chartData"] = this.HistoryChartObj[this.chartname].chartData;
    //     this.HistoryChartObj[this.chartname].chartData.forEach(element => {
    //       if (this.HistoryChartObj[this.chartname]["datapoint" + element.index] == tag.tag)
    //       {
    //         this.liveObj["tag" + element.index] = tag.tag;
    //         this.liveObj["value" + element.index] = tag.value;
    //         this.liveObj.unit = tag.unit;
    //       }
    //     });
    //   }
    // })
  }

  download(state, chartname?) {
    let json;
    let collection = this.historyResponse;
    if (state == 'live') {
      json = {
        assetcode: this.selectedAssetData.assetcode,
        mode: 'download',
        tags: [],
        asset: this.selectedAssetData.asset,
      };
      if (this.HistoryChartObj[chartname]['tag_info']) {
        json.tags = this.HistoryChartObj[chartname]['tag_info'];
      }
    } else if (state == 'history') {
      if (chartname != 'compare') {
        json = {
          assetcode: this.selectedAssetData.assetcode,
          mode: 'download',
          tags: [],
          asset: this.selectedAssetData.asset,
        };
        if (this.HistoryChartObj[chartname]['multiple']) {
          json.tags = this.HistoryChartObj[chartname]['tag_info'];
        }
      } else {
        // if (this.selectedForCompare.length != 0)
        // {
        //   json = {
        //     assetcode: this.assetcode,
        //     mode: "download",
        //     tags: this.selectedForCompare,
        //     asset: "Press"
        //   };
        // } else
        // {
        //   return;
        // }
      }
    }
    let array: any = { csv: [], title: '', filename: '' };
    array.title = this.selectedAssetData.assetcode + ' - ' + chartname;
    array.filename = chartname;
    let dataPoint: any = {};
    dataPoint['asset'] = this.selectedAssetData.asset;
    dataPoint['assetcode'] = this.selectedAssetData.assetcode;
    dataPoint['assettype'] = this.selectedAssetData.assettype;
    dataPoint['customer'] = this.selectedAssetData.customer;
    dataPoint['site'] = this.selectedAssetData.site;
    dataPoint['department'] = this.selectedAssetData.department;

    for (let t = 0; t < json.tags.length; t++) {
      let chartTagName = json.tags[t];
      dataPoint['tag'] = chartTagName;
      if (chartTagName && chartTagName != '') {
        for (let d = 0; d < this.selectedWidgetTags[chartTagName].length; d++) {
          dataPoint['messageid'] = this.selectedWidgetTags[chartTagName][d].x;
          dataPoint['timestamp'] = moment(
            this.selectedWidgetTags[chartTagName][d].x
          ).utc()['_d'];
          dataPoint['value'] = this.selectedWidgetTags[chartTagName][d].y;
          dataPoint['unit'] = this.HistoryChartObj[chartname]['unit'];
          let temp = Object.assign({}, dataPoint);
          array.csv.push(temp);
        }
      }
    }

    // collection.forEach((c:any) => {
    //   let obj2: any = {};
    //   json.tags.forEach((t:any) => {
    //     let tagFilter = c.tags.filter((fdata:any)=>{
    //       return fdata.tag==t || fdata.component==t;
    //     });
    //     if(tagFilter.length>0) {
    //       obj2["asset"] = c.asset;
    //       obj2['assetcode'] = c.assetcode;
    //       obj2['assettype'] = c.assettype;
    //       obj2['customer'] = c.customer;
    //       obj2["site"] = c.site;
    //       obj2['department'] = c.department;
    //       obj2['groupid'] = c.groupid;
    //       obj2['messageid'] = c.messageid;
    //       obj2['timezone'] = c.timezone;
    //       obj2["timestamp"] = moment(c.timestamp.seconds).utc()['_d'];
    //       obj2['tag'] =tagFilter[0].tag;
    //       obj2['value'] =tagFilter[0].value;
    //       let filterDataPoins = array.csv.filter((fdata)=>{
    //         return fdata.messageid == c.messageid;
    //       });
    //       if(filterDataPoins.length==0) {

    //       }

    //     }
    //   });
    // })
    this.csvexport(array);
  }

  csvexport(data) {
    if (data) {
      this.csvoptions['title'] = data.title;
      this.csvoptions['filename'] = data.filename;
      const csvExporter = new ExportToCsv(this.csvoptions);
      csvExporter.generateCsv(data.csv);
    }
  }
  /************************************************************** */

  redirectMonitor() {
    let uniqueId = (
      this.selectedAssetData.assetcode + this.selectedAssetData.asset
    )
      .toUpperCase()
      .replace(/ /g, '_');
    this.router.navigate(['page/monitor', uniqueId]);
  }

  redirectPage(type: string) {
    if (type == 'dashboard') {
      this.router.navigate(['page/assetgrid']);
    } else if (type == 'assetdetail') {
      this.router.navigate(['page/assetgrid/assetdetails', this.uniqueCode]);
    } else if (type == 'monitor') {
      this.router.navigate(['page/assetgrid/monitor', this.uniqueCode]);
    } else if (type == 'dataset') {
      this.router.navigate(['page/assetgrid/dataset', this.uniqueCode]);
    }
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  widthChange(data: any) {
    this.widthList.selected = data;
    this.widgetForm.controls['width'].setValue(data.value);
  }

  widgetCategoryChange(data: string) {
    this.widgetCategory.selected = data;
    this.loadWidgetListData();
    //this.widgetForm.controls["category"].setValue(this.widgetCategory.selected.id);
  }

  widgetOptionChange(event: any, type: string) {
    if (!this.addWidgetObj.options) {
      this.addWidgetObj.options = {};
    }
    this.addWidgetObj.options[type] = event;
    // if(type=="positionCategory") {
    //   this.addWidgetObj.options.positionCategory = event;
    // }
  }

  addMoreFormula() {
    this.formulaArray.push(this.addFormula());
  }

  get formulaArray() {
    const control = <FormArray>(
      (<FormGroup>this.widgetForm.get('formulas'))?.get('PRE')
    );
    return control;
  }

  private process(data): any {
    let result = [];
    result = data.map((item) => {
      return this.toTreeNode(item);
    });
    return result;
  }

  private toTreeNode(node, parent = null) {
    if (node && node.children) {
      node.children.map((item) => {
        return this.toTreeNode(item, node);
      });
    }
    return node;
  }

  addTagMapping() {
    if (!this.addWidgetObj.options) this.addWidgetObj.options = {};
    if (!this.addWidgetObj.options.tagMapping)
      this.addWidgetObj.options.tagMapping = [];
    this.addWidgetObj.options.tagMapping.push({ label: '', tag: '' });
  }

  deleteTagMapping(index) {
    // this.addWidgetObj.options.tagMapping.removeAt(index);
    this.addWidgetObj.options.tagMapping.splice(index, 1);
  }
  getRunningHrs() {
    let filter = [
      {
        key: 'assetcode',
        symbol: '==',
        value: 'OPCUARHF2',
      },
      {
        key: 'type',
        symbol: '==',
        value: 'hourly',
      },
    ];
    let params = {
      limit: 1,
      orderby: 'Date',
      asset: 'RHF2',
      conditions: filter,
    };
    this.currentShift = '';
    this.prev_shift = '';
    this.kpiData = [];
    this.subsink.sink = this.fs.getLiveKPI(params).subscribe((data: any) => {
      if (data.length) {
        this.rhf2Data = data[0];
        this.getAnalyticsData();
      }
    });
  }
  getAnalyticsData() {
    let filter = [
      {
        key: 'assetcode',
        symbol: '==',
        value: this.selectedAssetData.assetcode,
      },
      {
        key: 'type',
        symbol: '==',
        value: 'hourly',
      },
    ];
    let params = {
      limit: 1,
      orderby: 'Date',
      asset: this.selectedAssetData.asset,
      conditions: filter,
    };
    this.currentShift = '';
    this.prev_shift = '';
    this.kpiData = [];
    this.liveKpiData = [];
    this.productionData=[]
    let consumption;
    if (kpiData['default'][this.selectedAssetData.assetcode]) {
      this.kpiData = kpiData['default'][this.selectedAssetData.assetcode].data;
      let consumptionr = 37515.56;
      this.subsink.sink = this.fs.getKPIData(params).subscribe((data: any) => {
        if (data.length) {
          this.subsink.sink = this.fs
            .getStaticLiveData({ assetcode: this.selectedAssetData.assetcode })
            .subscribe((live) => {
              if (
                live.size &&
                this.selectedAssetData.assetcode == 'OPCUARHF2'
              ) {
                let doc;
                live.forEach((element) => {
                  doc = element.data();
                });
                consumption = doc.tags.filter((tag) => {
                  return tag.tag == 'TOTAL_BFG_CON_NEW';
                });
              }
              if (
                (live.size && this.selectedAssetData.asset == 'BD') ||
                this.selectedAssetData.asset == 'BD2' ||
                this.selectedAssetData.asset == 'tandem'
              ) {
                if (
                  kpiData['default'][this.selectedAssetData.assetcode].liveKpi
                ) {
                  this.liveKpiData =kpiData['default'][this.selectedAssetData.assetcode].liveKpi;
                }
                this.subsink.sink = this.fs
                  .getLiveData({ assetcode: this.selectedAssetData.assetcode })
                  .subscribe((liveData: any) => {
                    this.LiveTimestamp = moment(
                      liveData[0].timestamp.seconds * 1000
                    ).format('DD-MM-YYYY, hh:mm:ss a');
                    if (liveData && liveData.length) {
                      liveData[0].tags.forEach((tags: any) => {
                        this.liveKpiData.forEach((defaultTag: any) => {
                          if (tags.component == defaultTag.tag) {
                            defaultTag['value'] = tags.value;
                          }
                        });
                      });
                    } else {
                    }
                  });
              }
              let rhf2_filter = [
                {
                  key: 'assetcode',
                  symbol: '==',
                  value: 'OPCUARHF2',
                },
                {
                  key: 'type',
                  symbol: '==',
                  value: 'hourly',
                },
              ];
              let rhf2_params = {
                limit: 1,
                orderby: 'Date',
                asset: this.selectedAssetData.asset,
                conditions: rhf2_filter,
              };
              let total_run_hours_rhf2: any = 0;
              this.subsink.sink = this.fs
                .getKPIData(rhf2_params)
                .subscribe((rhf2_data: any) => {
                  rhf2_data[0].KPIS.forEach((kpi: any) => {
                    if (kpi.name == 'Total_running_hours') {
                      total_run_hours_rhf2 = Number(Number(kpi.value) / 60);
                    }
                  });

                  // let KPI = {...data[0], ...{'Total_running_hours':this.rhf2Data['Total_running_hours']}} ;
                  let KPI = data[0];
                  let avgRateOfProduction: any = 0;
                  let total_blooms: any = 0;
                  let total_run_hours: any = 0;
                  KPI.KPIS.forEach((kpi: any) => {
                    if (kpi.name == 'Total_Blooms') {
                      total_blooms = Number(kpi.value);
                    }
                    // if(kpi.name=='Total_running_hours'){
                    // total_run_hours=Number((Number(kpi.value))/60)
                    // }
                    if (total_run_hours_rhf2 && total_blooms) {
                      avgRateOfProduction = Number(
                        total_blooms / total_run_hours_rhf2
                      );
                    }
                    this.kpiData.forEach((defaultKpi: any) => {
                      if (defaultKpi.tag == 'Houly_BFG_Consumption') {
                        defaultKpi.value = Number(consumption[0].value).toFixed(
                          0
                        );
                      }
                      if (defaultKpi.tag == 'Production_Rate(blooms/hour)') {
                        defaultKpi.value = Math.round(
                          Number(avgRateOfProduction)
                        );
                      } else if (defaultKpi.tag == kpi.name) {
                        defaultKpi.value = Number(kpi.value).toFixed(0);
                      }
                    });
                  });
                  //     let HFOconsumption = doc.tags.filter(tag =>{
                  //       return tag.tag== 'TOTAL_HFO_CONP_24HRS'
                  //     })
                  //     consumptionr+=400
                  //     // KPI = {...KPI, ...{TOTAL_BFG_CON_NEW:Number(consumptionr)}}
                  //     KPI = {...KPI, ...{TOTAL_BFG_CON_NEW:Number(consumption[0].value),TOTAL_HFO_CONP_24HRS:Number(HFOconsumption[0].value)}}

                  //   }
                  this.currentShift = KPI['Shift_Running'];
                  this.lastUpdated = moment(KPI['Date'].seconds * 1000).format(
                    'hh:mm a DD-MMM'
                  );
                  this.prev_shift = this.previousShift[KPI['Shift_Running']];
                  filter[1].value = 'shift';
                  // filter.push({key:"Shift_Running", symbol:"==", value:this.prev_shift})
                  params.conditions = filter;
                  // params.limit = this.selectedAssetData.assetcode =='OPCUARHF2'?2:1
                  params.limit = 1;
                  this.subsink.sink = this.fs
                    .getKPIData(params)
                    .subscribe((data: any) => {
                      let prevShiftKpi: any;
                      if (data.length) {
                        prevShiftKpi = data[0];
                        prevShiftKpi.KPIS.forEach((kpi: any) => {
                          this.kpiData.forEach((defaultKpi: any) => {
                            if (defaultKpi.tag == kpi.name) {
                              defaultKpi.value = Number(kpi.value).toFixed(0);
                            }
                          });
                        });
                        if(kpiData['default'][this.selectedAssetData.assetcode].production_data){
                          this.productionData =kpiData['default'][this.selectedAssetData.assetcode].production_data
                        }
                        let prod_param: any = {
                          startDate: moment()
                            .subtract(1, 'day')
                            .startOf('day')
                            .toDate(),
                          endDate: moment()
                            .subtract(1, 'day')
                            .endOf('day')
                            .toDate(),
                        };
                        this.fs
                          .getProductionData(prod_param)
                          .subscribe((data: any) => {
                            if (data && data.length) {
                              let production = Number( Number(data[0]['Production']) / 1000);
                              let run_hr =(Number(data[0]['Mill_utilization']) / 100) * 24;
                              this.productionData[0]['value']=production.toFixed(0)
                               this.productionData[1]['value']=run_hr.toFixed(0)
                              this.productionData[2]['value']=(this.productionData[0]['value']/ this.productionData[1]['value']).toFixed(2)
                              this.productionData[3]['value']=data[0]['Mill_utilization'].toFixed(2)
                            }
                          });
                      }
                    });
                });
              // this.subsink.sink =  this.fs.getAnalyticsData(params).subscribe((shiftData: any) => {
              //   if (shiftData.status == 'success' && shiftData.data.length) {
              //     let shift = {...shiftData.data[0], ...shiftData.data[1]}
              //     this.kpiData.forEach((kpi) => {
              //       if (KPI[kpi.tag] >= 0 && !kpi.shift) {
              //         if(kpi.tag == "Production_Rate(blooms/hour)"){
              //           if( KPI['Total_running_hours']){
              //             kpi.value =( KPI.Total_Blooms / (KPI['Total_running_hours']/60)).toFixed(2)
              //           }else{
              //             kpi.value = 'NA'
              //           }

              //           // var format = 'HH:mm:ss'
              //           // let end  = moment(KPI['Date'].seconds * 1000);
              //           // let time = moment(end.format(format),format)
              //           // let beforeTime = moment('22:00:00', format)
              //           // let afterTime = moment('23:59:59', format);
              //           // let total_hrs = 0
              //           // if (time.isBetween(beforeTime, afterTime)){
              //           //  let start =  moment('22:00:00',format)
              //           //  total_hrs =  end.diff(start, 'minutes')/60
              //           //  total_hrs = total_hrs >0 ? total_hrs : 1
              //           // }else{
              //           //   let start =  moment('22:00:00',format).subtract(24, 'hours')
              //           //   total_hrs =  end.diff(start, 'minutes')/60
              //           //   total_hrs = total_hrs >0 ? total_hrs : 1
              //           // }
              //         }else{
              //           kpi.value = Number(parseInt(KPI[kpi.tag]))
              //         }
              //       }else if(shift[kpi.tag] >= 0 && kpi.shift){
              //         if(kpi.tag== 'Total_Bfg_Consumption'){
              //           kpi.value = Number(parseInt(shift[kpi.tag]));
              //         }else{
              //         kpi.value = Number(parseInt(shift[kpi.tag]));
              //         }
              //       }
              //     });
              //   }
            });
          // })
        }
      });
    }
  }
  alertAction() {}
  checkAction(action) {
    let access = this.util.getAccessForAction('Dashboard', '', action);
    return access;
  }
  filterSearch() {
    this.alertFilterCount = 0;
    // let filterData = this.getFilter();
    // this.loadData(filterData);
    let customFilter: any = {};
    this.alertFilter.forEach((filter) => {
      if (filter.selected !== 'Select') {
        this.alertFilterCount++;
        if (filter.id == 'important') {
          customFilter[filter.id] = filter.selected == 'Yes' ? true : false;
        } else {
          customFilter[filter.id] = filter.selected;
        }
      }
    });
    this.alertList = _.filter(this.totalAlertList, customFilter);
    this.modalService.dismissAll();
  }
  totalAlertList(totalAlertList: any, customFilter: any): any {
    throw new Error('Method not implemented.');
  }

  filterClear() {
    this.alertFilterCount = 0;
    this.alertFilter.forEach((data) => {
      data.selected = 'Select';
    });
  }

  closePopup() {
    Swal.fire({
      title: 'Do you want to close?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Close',
      denyButtonText: 'Cancel',
    }).then((result) => {
      this.util.setLoaderValue(true);
      if (result.isConfirmed) {
        this.util.setLoaderValue(false);
        this.modalService.dismissAll();
      } else if (result.isDenied) {
        this.util.setLoaderValue(false);
      }
    });
  }

  sidebarUserSpecificChange(data) {
    this.userSpecific.selected = data
  }

  changeChartType(data) {
    this.chartType.selected = data;
  }

  getWidgetLength(type) {
    let widgetCOnfigData = type=="widgetRender"?this.widgetData:this.widgetListData;
    let filterData = widgetCOnfigData.filter((widget)=>{
      return !widget.userSpecific || (widget.userSpecific && widget.userSpecific == 'Current User' && widget.email==this.loggedinUser.email)
    })
    return filterData.length;
  }
}
